.messages-container {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;

  .message-wrapper {
    padding: 5px 30px 5px 5px;
    display: flex;

    .message {
      display: flex;
      flex-direction: column;

      .message-sender {
        display: flex;
        padding: 5px 0;
        font-size: 12px;
        color: #0e4570;
        font-weight: 500;
      }
      .message-body {
        display: flex;
        padding: 10px;
        background-color: #b4d7fa;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        font-size: 12px;
      }
      .date {
        padding: 5px 0;
        font-size: 10px;
        color: #3c3c3c;
      }
    }
    &.response {
      justify-content: flex-end;
      padding: 10px 10px 10px 30px;
      .message {
        align-items: flex-end;
        .message-body {
          justify-content: flex-end;
          text-align: right;
          background-color: #d7ecfd;
        }
      }

    }
  }
}
