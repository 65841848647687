.industry-filter {
  padding: 10px 15px;
  background-color: #fff;
  .industry-search-section {
    position: relative;
    margin-bottom: 15px;
    .close-btn {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
      padding: 3px;
      color: #ccc;
      &:hover {
        color: #5b5b5b;
      }
    }
    .form-control {
      border: 1px solid #ccc!important;
      padding-right: 25px;

      &:focus, &:active {
        border-color: #5b5b5b!important;
      }
    }
    .hint {
      font-size: 12px;
      padding: 0 10px;
    }
  }
  .selected-industries {
    display: flex;
    flex-wrap: wrap;
  }
  .industry-list {
    list-style: none;
    padding: 0;
    margin: 0;

    & > .dropdown-item {
      padding: 0;
      border-bottom: 1px solid #dadada;
      
      &.selected {
        .dropdown-item_content {
          .checked-icon {
            background-image: url('../../../../images/icons/selected-parent.svg');
          }
        }
      }

      &.partial {
        .dropdown-item_content {
          .checked-icon {
            background-image: url('../../../../images/icons/selected-partially.svg');
          }
        }
      }
    }
    .sub-list {
      padding: 0;
      .dropdown-item {
        display: flex;
        align-items: center;
        padding-left: 40px;
        padding-right: 5px;

        .industry-name {
          margin-bottom: 0;
        }

        &:focus,
        &:active,
        &:hover {
          background-color: #e7eef5;
          color: #222529;
        }


        &.selected {
          .checked-icon {
            background-image: url('../../../../images/icons/selected.svg');
          }
        }
      }
    }
    .dropdown-item {
      cursor: pointer;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      margin: 0;
      white-space: normal;

      .dropdown-item_content {
        padding: 5px;
        display: flex;
        align-items: center;

        .industry-name {
          margin-left: 4px;
          margin-bottom: 0;
        }

        &:focus,
        &:active,
        &:hover {
          background-color: #e7eef5;
          color: #222529;
        }
      }

      .checked-icon {
        display: flex;
        cursor: pointer;
        width: 20px;
        min-width: 20px;
        height: 20px;
        background: url("../../../../images/icons/unselected.svg") no-repeat center/cover;
        margin-left: auto;
      }

      .folder-icon-wrapper {
        margin-right: 3px;

        .folder-icon {

          width: 10px;
          height: 10px;
        }
      }

      &:focus,
      &:active,
      &:hover,
      &.selected {
        background-color: transparent;
        color: #222529;
      }
    }
  }
}
