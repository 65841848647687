.textarea_test {
    background-color: #fafafa;
    border: 1px solid #c1c1c1;
    color: #000;
    font-size: 14px;
    padding: 6px 20px;
    min-height: 90px!important;
    border-radius: .25rem;
    display: block;
    width: 100%;
}

/* .franchises-bx {
    height: 464px;
} */


    
    .pricing-divider {
    border-radius: 20px;
    background: #3890da;
    padding: 1em 0 4em;
    position: relative;
    }
    .blue .pricing-divider{
    background: #2D5772; 
    }
    .green .pricing-divider {
    background: #1AA85C; 
    }
    .red b {
      color:#3890da;
    }
    .blue b {
      color:#2D5772
    }
    .green b {
      color:#1AA85C
    }
    .pricing-divider-img {
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 80px;
    }
    .deco-layer {
        -webkit-transition: -webkit-transform 0.5s;
        transition: transform 0.5s;
    }
    .btn-custom  {
      background:#3890da; color:#fff; border-radius:20px
    }
    
    .img-float {
      width:50px; position:absolute;top:-3.5rem;right:1rem
    }
    
    .princing-item {
      transition: all 150ms ease-out;
    }
    .princing-item:hover {
      transform: scale(1.05);
    }
    .princing-item:hover .deco-layer--1 {
      -webkit-transform: translate3d(15px, 0, 0);
      transform: translate3d(15px, 0, 0);
    }
    .princing-item:hover .deco-layer--2 {
      -webkit-transform: translate3d(-15px, 0, 0);
      transform: translate3d(-15px, 0, 0);
    }
    
    
    section.pricing .btn {
      background-color: rgb(56, 144, 218);
    }
    
    .pricing .card {
      border: none;
      border-radius: 1rem;
      transition: all 0.2s;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    }
    
    .pricing hr {
      margin: 1.5rem 0;
    }
    
    .pricing .card-title {
      margin: 0.5rem 0;
      font-size: 1.3rem;
      letter-spacing: .1rem;
      font-weight: bold;
    }
    
    .pricing .card-price {
      font-size: 2.5rem;
      margin: 0;
    }
    
    .pricing .card-price .period {
      font-size: 0.8rem;
    }
    
    .pricing ul li {
      margin-bottom: 0.6rem;
      font-size: 15px;
    }
    
    .pricing .text-muted {
      opacity: 0.7;
    }
    
    .pricing .btn {
      font-size: 80%;
      border-radius: 5rem;
      letter-spacing: .1rem;
      font-weight: bold;
      padding: 1rem;
      /* opacity: 0.7; */
      transition: all 0.2s;
    }
    
    /* Hover Effects on Card */
    
    @media (min-width: 992px) {
      .pricing .card:hover {
        margin-top: -.25rem;
        margin-bottom: .25rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
      }
      .pricing .card:hover .btn {
        opacity: 1;
      }
    }



    .switch {
      position: relative;
      display: inline-block;
      width: 110px;
      height: 34px;
    }
    
    .switch input {display:none;}
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      -webkit-transition: .4s;
      transition: .4s;
       border-radius: 34px;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
    
    input:checked + .slider {
      background-color: rgb(56, 144, 218);
    }
    
    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(75px);
    }
    
    /*------ ADDED CSS ---------*/
    .slider:after
    {
     content:'Monthly';
     color: white;
     display: block;
     position: absolute;
     transform: translate(-50%,-50%);
     top: 50%;
     left: 50%;
     font-size: 10px;
     font-family: Verdana, sans-serif;
    }
    
    input:checked + .slider:after
    {  
      content:'Annual';
    }