.sponsored-advisors_carousel {
  padding: 0 15px;
  overflow: hidden;

  .carousel-indicators {
    bottom: -25px;

    li {
      background-color: #1F69A7;
    }
  }

  .carousel-inner, .carousel.slide {
    position: relative;
    padding: 15px;

    .business-card {
      height: 150px !important;
      padding: 25px !important;

      &__image {
        align-items: start !important;
        min-width: 100px;
        position: relative;

        .type-badge {
          font-size: 11px;
          line-height: 12px;
          padding: 4px 8px;
          white-space: nowrap;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
          position: absolute;
          background-color: #AFAFAF;
          color: #fff;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .professional-card__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .professional-card__description {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 475px) {
          -webkit-line-clamp: 2;
        }
      }

      .boosted-profile_contact-button {
        display: flex;
        margin-left: 15px;
        align-items: center;

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-width: 117px;

          svg {
            margin-right: 5px;
          }
        }
      }

      .boosted-profile-home {
        position: static !important;
        left: 0 !important;
        display: flex;
        margin-left: 15px;
        width: 100%;
        justify-content: space-between;

        .professional-card__location,
        .professional-card__title {
          margin-bottom: 5px;
        }
      }

      &.promo-card {
        @media (max-width: 575px) {
          padding: 15px 0;

          .promo-img img {
            width: 65px !important;
            height: 65px !important;
          }
        }
        @media (max-width: 475px) {
          padding: 15px 0;

          .promo-img img {
            width: 55px !important;
            height: 55px !important;
          }
        }
      }
    }

    @media (max-width: 575px) {
      padding: 15px 0;
    }
  }

  .prev-control, .next-control {
    color: #1F69A7;
  }

  .carousel-control {
    &-prev {
      left: -5px;
      width: 20px;
      transform: rotate(90deg);

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &-next {
      right: -5px;
      width: 20px;
      transform: rotate(-90deg);

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (max-width: 1199px) {
    .business-card {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      &__image {
        width: auto;
      }
    }
  }

  @media (max-width: 600px) {
    margin: 0 -15px;
    .promo-card.business-card {

      .boosted-profile-home {
        flex-direction: column;
      }

    }

    .carousel-inner, .carousel.slide {

      .business-card {
        padding: 16px!important;
        height: 120px!important;
        .boosted-profile-home {
          margin-left: 10px;
        }

        .professional-card__title,
        .professional-card__description {
          font-size: 12px;
          line-height: 16px;
        }
        .professional-card__location span {
          font-size: 12px;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .boosted-profile_contact-button {
          //display: none!important;
          margin-left: 10px;
          svg {
            display: none;
          }
          .btn {
            padding: 2px 5px;
            font-size: 9px;
            min-width: 55px;
          }
        }

        .business-card__image {
          min-width: 65px;
          height: 70px;
          .type-badge {
            //white-space: normal;
            max-width: 65px;
            padding: 2px 3px;
            text-align: center;
            font-size: 9px;
            line-height: 11px;
          }

          img {
            width: 65px !important;
            height: 65px !important;
          }
          @media (max-width: 475px) {
            min-width: 55px;
            height: 60px;

            img {
              width: 55px !important;
              height: 55px !important;
            }
          }
        }
      }
    }
  }
}

.d-block.d-sm-none {
  @media (max-width: 575px) {
    display: none !important;
  }
}

.d-none.d-sm-block {
  @media (max-width: 575px) {
    display: block !important;
  }
}
