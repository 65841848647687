.search-loader {
    position: fixed;
    z-index: 99999;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
}
@media (max-width: 990px) {
    .search-loader {
        display: none;
    }

}
.search-loader .loader_listings_page {
    box-shadow: 1px 1px 20px #b3b1b1;
    background: linear-gradient(110deg, rgb(236 236 236 / 50%) 8%, rgb(245 245 245 / 50%) 18%, rgb(236 236 236 / 50%) 33%);
}
.search-loader .spinner-wrapper {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.search-loader .spiner {    width: 40px;
    height: 40px;
    position: static;
    margin: 0;
    border: 4px solid #ffffff;
    border-top: 4px solid #3498db;
}
