.homepage {
  .no-link-style {
    color: #373c41
  }
  .container {
    .middle-content,
    .sidebar-left,
    .sidebar-right {
      @media (max-width: 1200px) {
        &.d-none.d-block {
          display: block!important;
        }
      }
    }
  }
  // Dropdown menu
  .dropdown-menu {
    border-radius: 6px;
    box-shadow: 1px 1px 20px #e1e1e1;
    border-color: rgba(197, 197, 197, 15%);

    &.dropdown-menu--minw-260 {
      min-width: 260px;
    }

    &.dropdown-menu--minw-310 {
      min-width: 310px;
    }
  }

  .dropdown-item, .dropdown-content-padding {
    padding: 6px 20px;
  }

  /* Mobile Nav */
  .mobile-nav {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    background-color: #fff;

    a {
      flex: 0 0 1;
      width: 100%;
      color: #ADB9C2;
      padding-bottom: 10px;
      padding-top: 10px;

      &.active {
        color: $primary-blue;
        border-bottom: 2px solid $primary-blue;
      }
    }

    @media (min-width: 1200px) {
      display: none;
    }
  }

  /* Layout */
  .sidebar-left {
    flex: 0 0 100%;
    @media (min-width: 1200px) {
      flex: 0 0 200px;
    }
  }

  .middle-content {
    display: none;
    @media (min-width: 1200px) {
      display: block;
      width: 50%;

      // -ms-overflow-style: none; /* IE and Edge */
      // scrollbar-width: none;
      // max-height: 100vh;
      // overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .sidebar-right {
    display: none;
    flex: 0 0 100%;
    @media (min-width: 1200px) {
      display: block;
      flex: 0 0 350px;
    }
  }

  .content-section-padding:first-child {
    padding-bottom: 20px;
  }

  .content-section-padding:not(:first-child) {
    padding: 25px 0;
  }

  .profile-card {
    padding: 25px 10px;
    background-color: #f8f8f8;
    box-shadow: 1px 1px 20px #e1e1e1;
    border-radius: 10px;

    // .profile-card__avatar
    &__avatar {
      max-width: 100px;
      border-radius: 100px;
      border: 3px solid #fff;
      margin-bottom: 9px;

      &-progress {
        position: absolute;
        top: 0;
        right: -20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        line-height: 1;
        background-color: $primary-black;
        color: $primary-blue;
        font-size: 14px;
        border-radius: 25px;
        width: 45px;
        height: 25px;
      }
    }

    // .profile-card__completeness
    &__completeness {
      position: relative;
      background-color: #fff;
      border-radius: 10px;
      padding: 33px 6px 15px 6px;
      font-size: 12px;
      color: #0f4778;
      text-align: center;
      margin-top: 40px;
      max-width: 190px;
      margin-left: auto;
      margin-right: auto;

      &-progress {
        position: absolute;
        width: 52px;
        height: 52px;
        top: -26px;
        left: 50%;
        transform: translateX(-50%);
        background: #edf6fe;
        border-radius: 52px;

        .progress-icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
          font-size: 20px;
          margin-top: -2px;
          line-height: 1;
        }
      }
    }

    // .profile-card__nav
    &__nav {
      max-width: 190px;
      margin-left: auto;
      margin-right: auto;
      padding: 25px 18px 0;

      li {
        margin-bottom: 9px;
      }

      &-link,
      &-link-dropdown {
        display: inline-flex;
        align-items: center;
      }

      &-link {
        color: $secondary;

        &:hover {
          color: darken($secondary, 12%);
        }
      }

      &-link-dropdown {
        color: $primary-blue;

        &:hover {
          color: darken($primary-blue, 12%);
        }
      }
    }
  }

  .sidebar-card {
    background-color: #f8f8f8;
    box-shadow: 1px 1px 20px #e1e1e1;
    border-radius: 10px;
    padding: 25px 20px;

    &.highlighted {
      box-shadow: 1px 1px 20px $primary-blue;
    }
  }

  .news-card {
    display: block;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(187, 187, 187, 0.25);
    border-radius: 10px;
    padding: 14px 20px;
    color: inherit;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &__title {
      font-size: 14px;
      line-height: 1.5;
      font-weight: 500;
    }

    &__description {
      font-size: 13px;
      margin-bottom: 10px;
    }

    &__date {
      font-size: 12px;
      color: #6d7277;
    }
  }

  .tags {
    margin-top: 16px;
    margin-bottom: 16px;

    .btn-tag {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }

  .popular-searches {
    padding: 0 20px;
    overflow: auto;

    .btn {
      margin-right: 10px;
      margin-bottom: 10px;
      white-space: nowrap;
    }
  }

  .professional-card {
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 15px #C2C2C2;
    border-radius: 10px;
    padding: 60px 8px 0;
    margin-top: 52px;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    &.professional-card--horizontal {
      padding: 60px 20px 10px;

      .professional-card__image {
        left: 15px;
      }

      .professional-card__btn {
        // position: absolute;
        right: 10px;
        // top: 60px;
        bottom: 100px;
        //top: 110px;
        width: 150px;
        height: 45px;
        border-radius: 25px;
        padding-left: 25px;
        padding-right: 25px;
      }
    }

    &__image {
      position: absolute;
      top: -40px;
      left: calc(50% - 40px);
      width: 80px;
      height: 80px;
      border: 3px solid #fff;
      border-radius: 80px;
    }

    &__title {
      font-size: 14px;
      font-weight: 500;
    }

    &__location {
      font-size: 12px;
      color: #0F4778;
      margin-bottom: 8px;
    }

    &__description {
      font-size: 13px;
    }

    &__btn {
      background-color: $primary-black;
      color: #fff;
      width: calc(100% + 16px);
      margin-left: -8px;
      border-radius: 0 0 10px 10px;

      &:hover {
        background-color: #29be37;
      }
    }
  }

  .nav-tabs.nav-tabs--1 {
    padding-bottom: 8px;

    a {
      color: #829fb9;

      &.active {
        font-weight: 600;
        color: #373c41;
      }
    }
  }

  .modal--filters {
    .modal-dialog {
      margin: 0;
      max-width: 100%;
    }

    .modal-content {
      min-height: 100vh;
    }

    .modal-body {
      background-color: #F8F8F8;
    }

    .modal-footer {
      justify-content: center;
    }
  }


  .dropdown-menu .dropdown-menu:not(.advanced-dropdown){
    top: auto;
    left: 100%;
    transform: translateY(-2rem);
  }
  .dropdown-item + .dropdown-menu:not(.advanced-dropdown) {
    display: none;
  }
  .dropdown-item.submenu::after {
    content: '→';
    right: 0;
    position: absolute;
    margin-right: 10px;
  }
  .dropdown-item:hover + .dropdown-menu,
  .dropdown-menu:hover:not(.advanced-dropdown) {
    display: block;
  }

  .alert {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;

    span, .btn {
      margin: 6px 0;
    }
    span {
      margin-right: 10px;
    }
  }
}
