.disabled-date-picker {
  color: #666;
  input {
    cursor: not-allowed;
  }

}
.user-subscription-modal {
  .custom-control-input:disabled {
    cursor: not-allowed;

  }
}
