.viewed-deals-wrapper {
  .table {
    td {
      padding: 10px;
    }
    th {
      padding: 10px;
    }
    #deal_status_select {
      cursor: pointer;
    }
  }
  .tab-pane {
    .pagination {
      margin-top: 10px;
    }
    &>p {
      padding: 16px;
    }
  }
}
