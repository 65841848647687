.broker-listings-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;

  .h2 {
    display: flex;
    font-size: 22px;
    .badge {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      background: rgba(77, 143, 212, .15);
      border-radius: 80px;
      width: 40px;
      height: 28px;
      color: #4E8FD5;
      font-size: 14px;
    }
  }

  &__left {
    margin-right: 15px;
  }
  &__right {
    display: flex;
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;
  }

  .broker-listings-search {
    display: flex!important;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    &__right {
      margin-left: 0;
      margin-top: 16px;
    }

    
  }
}

.homepage {
  .popular-searches {
    overflow: visible;
  }
}
.broker-listings__link-row {
  display: flex;
  padding: 0 4px;

  @media (max-width: 768px) {
    width: 230px;
  }

  @media (max-width: 514px) {
    .broker-listing__link.post {
      width: calc(100% - 8px);
      margin: 0;
    }
  }
}
.broker-listing__link.post {
  background-color: #51A350;
  color: #fff;
}
.broker-listing__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: 100px!important;
  height: 58px;
  padding: 8px;
  background: rgba(77, 143, 213, 0.1);
  border-radius: 6px;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #4D8FD5;
  white-space: nowrap;

  &::before {
    content: '';
    width: 25px;
    height: 25px;
  }

  &.post::before {
    background: url('../images/icons/edit_note.svg') no-repeat center/cover;
  }

  &.boost::before {
    background-image: url('../images/icons/rocket_launch.svg');
  }

  &.advertise::before {
    background-image: url('../images/icons/auto_graph.svg');
  }

  &.advisor-panel::before {
    background-image: url('../images/icons/groups.svg');
  }

  &.strategy::before {
    background-image: url('../images/icons/call.svg');
  }

  & + .broker-listing__link {
    margin-left: 10px;
  }

  &:hover {
    opacity: .8;
  }

  @media (max-width: 768px) {
    margin-bottom: 15px;
    margin-right: 15px;
    & + .broker-listing__link {
      margin-left: 0;
    }
  }
}
