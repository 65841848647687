html body, input, button, select, textarea, .tooltip, .popover {
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	color: #333;
	font-weight: 400;
}

.ms-auto {
	margin-left: auto;
}

.me-auto {
	margin-right: auto;
}

body input[type="file"]:focus, body input[type="radio"]:focus, body input[type="checkbox"]:focus, body .btn:focus, body .btn:active:focus, body .btn.active:focus, body .btn.focus, body .btn:active.focus, body .btn.active.focus, body input[type="file"]:focus, body input[type="radio"]:focus, body input[type="checkbox"]:focus, body .checkbox input[type="checkbox"]:focus + label::before, body .checkbox input[type="radio"]:focus + label::before, .filter_option .radio input[type="radio"]:focus + label:before, button:focus {
	outline: none;
}
.rdw-option-wrapper img{
	width:auto !important;
	height:auto !important;
}
.sprite,.modal-dialog-centered .modal-header .close span,.profile-modal .modal-header .close span,.banner-selection .custom-radio .custom-control-input:checked ~ .custom-control-label::after,.profile-modal .react-datepicker__input-container::after,.brokers-list .geosuggest__input-wrapper::before,.search-control .geosuggest__input-wrapper::before,.custom-menu-category .custom-checkbox .custom-control-label::before {
	background: url('images/sprite.png') no-repeat;
	display: inline-block;
	text-indent: -9999px;
	position: relative;
	vertical-align: middle;
}
.--v2{display: none}
.deal-profile-pic{

	background-color: #fff;
	border: 3px solid #fff;
	-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
	width: 183px;
	height: 183px;
	border-radius: 50%;
	text-align: center;
	line-height: 173px;
	margin:0 auto;

}
.listing-title{
	font-size: 23px;
	font-weight: 600;
}
p.error:empty{display: none;}
body{background-color: #f5f6f7;}
.modal-open {overflow: hidden;position: absolute;left: 0;right: 0;}	
.form-group{position: relative;}
.form-group .react-datepicker-popper {z-index: 7;}
.react-datepicker-popper .react-datepicker{font-family: 'Nunito Sans', sans-serif;font-weight:600;}
.header{background-color: #000;}
.header .navbar-brand{padding:.85rem 0;}
.header .navbar-nav > .nav-item > .nav-link{color: #fff; font-size: .9rem;padding:1rem;}
.header .dropdown-menu{margin-top: 0;background-color: #fff;-webkit-box-shadow:0px 2px 12px 0px rgba(0, 0, 0, 0.1);-moz-box-shadow:0px 2px 12px 0px rgba(0, 0, 0, 0.1);box-shadow:0px 2px 12px 0px rgba(0, 0, 0, 0.1);border-radius: 6px;}
.header .dropdown-toggle::after{border-top:.4em solid;border-right:.3em solid transparent;border-left:.3em solid transparent;vertical-align: middle;}
.header .dropdown-menu .nav-link{color: #3c3c3c;padding:.2rem 0rem;}
.header .navbar-toggler{padding: .25rem .0rem;}
.header .navbar-toggler-icon{background-image:url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")}
.modal-backdrop.show{background-color:#0a2a44;opacity: 0.7;}
.modal-dialog-centered .modal-header .close span,.profile-modal .modal-header .close span{width: 22px;height: 22px;background-position:-27px -34px;}
.modal-dialog-centered .modal-header .close,.profile-modal .modal-header .close{position: absolute;padding: 0;opacity: 1;right:28px;top:28px;}
.modal-dialog.confirm-your-identity{max-width: 555px;}
.modal-dialog-centered .modal-content{border-radius:8px;}
.modal-dialog-centered .modal-header,.profile-modal .modal-header{border: none;justify-content: center;border-top-left-radius:12px;border-top-right-radius:12px;padding-top:20px;padding-bottom: 0;}
.modal-dialog-centered.delete-item .modal-header{border: none;border-bottom: 1px solid #e9ecef;justify-content: left;border-top-left-radius:12px;border-top-right-radius:12px;padding:0.8rem 1rem;}
.modal-dialog-centered .modal-title,.profile-modal .modal-title{font-size: 24px;font-weight: 700;}
.confirm-your-identity p{font-size: 18px;color: #3c3c3c;line-height: 30px;}
.send-code{font-size: 14px;color: #4a98d2;font-weight: 300;}
.right-arrow{width: 15px;height: 9px;margin-left:5px;background-position: -55px -7px;}
.left-arrow{width: 15px;height: 9px;margin-right:5px;background-position: -35px -7px;}
.user{background-position:-81px -1px;}
.envelope{background-position:-109px 1px;}
.tele-phone{background-position:-137px 0px;}
.tele-phone-white{background-position:-68px -114px;}
.envelope-white{background-position:-100px -114px;}
.envelope-blue{background-position:-191px -3px;}
.tele-phone-blue{background-position:-162px -1px;}
.briefcase{background-position:-221px -3px;}
.map-locator{background-position:-246px -3px;}
.marker-location{background-position:-204px -58px;}
.envelope-email{background-position:-262px -2px;}
.i-home{background-position:-52px -29px;width:15px;height:13px;margin-top:-5px;}
.i-camera{background-position:-74px -29px;width:16px;height:14px;margin-top:-5px;margin-right: 10px;}
.i-camera-blue{background-position:-97px -29px;width:16px;height:14px;margin-top:-5px;}
.i-plus{background-position:-164px -29px;width:16px;height:16px;}
.i-delete{background-position:-206px -30px;width:14px;height:15px;}
.i-edit{background-position:-185px -30px;width:15px;height:15px;}
.i-down-arrow{background-position:-223px -40px;width:9px;height:5px;}
.i-up-arrow{background-position:-223px -31px;width:9px;height:5px;}
.i-down-arrow-white{background-position:-95px -68px;width:9px;height:6px;}
.i-up-arrow-white{background-position:-95px -60px;width:9px;height:6px;}
.i-info{background-position:-274px -31px;width:10px;height:10px;}
.i-star{background-position:-16px -60px;}
.i-starfill{background-position:0px -60px;}
.i-star-md{background-position:-181px -57px;}
.i-starfill-md{background-position:-160px -57px;}
.i-locator{background-position:-64px -59px;width:10px;height:15px;}
.i-listing-locator{background-position:-80px -60px;width:9px;height:13px;}
.i-sold-locator{background-position:-111px -61px;}
.i-sold-date{background-position:-128px -61px;}
.i-sold-category{background-position:-142px -61px;}
.agriculture{background-position:-52px -90px;}
.engineering{background-position:-73px -90px;;}
.food{background-position:-96px -90px;}
.franchise_resales{background-position:-116px -90px;}
.leisure{background-position:-137px -90px;}
.manufacturing{background-position:-156px -90px;}
.real_estate{background-position:-199px -90px;}
.retail{background-position:-220px -90px;}
.services{background-position:-241px -90px;}
.tech_media{background-position:-263px -90px;}
.wholesale_distribution{background-position:-32px -90px;}
.i-retail_,.i-retail{background-position:-122px -198px;}
.i-agriculture_,.i-agriculture{background-position:-217px -198px;}
.i-engineering_,.i-engineering{background-position:-241px -198px;;}
.i-food_,.i-food{background-position:-265px -198px;}
.i-franchise_resales{background-position:-26px -200px;}
.i-leisure_,.i-leisure{background-position:-50px -198px;}
.i-manufacturing_,.i-manufacturing{background-position:-2px -198px;}
.i-real_estate{background-position:-98px -198px;}
.i-services_,.i-services{background-position:-146px -198px;}
.i-tech_media{background-position:-170px -198px;}
.i-wholesale_distribution{background-position:-193px -198px;}
.gray-retail{background-position:-81px -249px;}
.gray-agriculture{background-position:-142px -249px;}
.gray-engineering{background-position:-158px -249px;}
.gray-food{background-position:-173px -249px;}
.gray-franchise_resales{background-position:-18px -249px;}
.gray-leisure{background-position:-34px -249px;}
.gray-manufacturing{background-position:-3px -249px;}
.gray-real_estate{background-position:-64px -249px;}
.gray-services{background-position:-96px -249px;}
.gray-tech_media{background-position:-111px -249px;}
.gray-wholesale_distribution{background-position:-127px -249px;}
.i-listview{background-position:-223px -59px;width:18px;height:15px;}
.i-gridview{background-position:-253px -58px;width:16px;height:16px;}
.i-next{background-position:-48px -117px;width:7px;height:11px;margin-top: -2px;}
.i-prev{background-position:-35px -117px;width:7px;height:11px;margin-top: -2px;}
.i-financing{background-position:-168px -116px;}
.i-expense{background-position:-124px -115px;}
.i-print{background-position:-146px -115px;}
.i-save{background-position:-211px -115px;}
.i-question{width: 17px;height: 17px;background-position:-190px -115px;}
.qualified-sellers{background-position:-67px -144px;}
.qualified-buyers{background-position:8px -144px;}
.get-started-free{background-position:-142px -144px;}
.caret-up{border-top: 0;border-right: 5px solid transparent;border-bottom:6px solid #4a98d2;border-left: 5px solid transparent;border-style: solid;display: inline-block;vertical-align: middle;margin-left: 5px;}
.caret-down{border-width: 6px 5px 2.5px;border-color: #4a98d2 transparent transparent;border-style: solid;display: inline-block;vertical-align: middle;margin-left: 5px;}
.signup-section{padding:50px 15px;}
.signup-bx{background-color: #fff;border-radius: 8px;width: 100%;max-width: 555px;margin:auto;padding: 30px;padding-top: 10px;margin-top: 25px;-webkit-box-shadow:0px 16px 25px 0px rgba(5, 21, 34, 0.25);-moz-box-shadow:0px 16px 25px 0px rgba(5, 21, 34, 0.25);box-shadow:0px 16px 25px 0px rgba(5, 21, 34, 0.25);}
.signup-other-bx{width: 100%;max-width: 655px;margin:auto;padding: 10px;}
.signup-bx h4{font-size: 24px;font-weight: 700;}
.signup-bx p{font-size: 14px;font-weight: 300;color: #020202;line-height: 19px;}
.signup-or{text-align: center;border-bottom: 1px solid #a3a3a3;margin-top: 10px;margin-bottom:30px;}
.signup-or span{background-color: #fff;padding:0 25px;font-size: 14px;color: #020202;font-weight: 600;position: relative;top: 12px;}
.signup-bx label,.confirm-your-identity label{font-size: 14px;font-weight: 500;}
.signup-bx .form-control,.confirm-your-identity .form-control,.form-group .Select__control,.profile-form .form-control, .form-control,.brokers-list .form-control,.contact-broker .form-control{background-color:#fafafa;border: 1px solid #c1c1c1;color: #000;font-size: 14px;padding: 6px 20px;height:47px;border-radius: .25rem;}
/* .profile-modal .row.form-group .form-control, .row.form-group.custom-menu-category .react-dropdown-tree-select .dropdown .dropdown-trigger,.profile-modal .row.form-group .dropzone, .row.form-group .Select__control, .profile-modal .row.form-group .react-datepicker__input-container{background-color: #fff;}
.profile-modal .row.form-group .react-datepicker-wrapper .form-control{background-color: transparent;} */
.form-control[disabled] {cursor: not-allowed;background-color: #e3e3e3;}
.signup-bx .form-control:focus,.confirm-your-identity .form-control:focus,.profile-form .form-control:focus, .form-control:focus,.contact-broker .form-control:focus{background-color:#fff;border-color: #007eff;}
.profile-form #about.form-control{resize: none;height:215px!important;}
.profile-form #description.form-control,#shareexperience.form-control,#message.form-control,.profile-form #testimonialsdescription.form-control{resize: none;height:125px!important;}
.longtextbox.form-control{resize: none;height:100px!important;}
#description.longtextbox.form-control{height: 160px !important;}
.form-group .Select--single > .Select__control .Select-value{padding: 6px 20px;}
.confirm-your-identity input[type="button"].form-control{color: rgba(0,0,0,0.50)}
.form-group .Select-placeholder{line-height: 48px;padding-left: 20px;color:#000}
.form-group.selectpicker .Select-placeholder{opacity: 0.5;}
.form-group .Select-arrow-zone{padding: 0!important;width: 10px;}
.search-index{ position:relative;z-index:10; }
.search-index .Select{z-index: 5;}
.form-group .Select-arrow, .search-control-border .Select-arrow{border-width: 6px 5px 2.5px;border-color: #7d7d7d transparent transparent;}
.form-group .Select.is-focused.is-open .Select__control,.search-control .search-control-border .Select.is-focused.is-open .Select__control,.custom-menu-category .show .dropdown-toggle:focus,.custom-menu-category .show > .dropdown-toggle,.search-control-border .dropdown .btn-secondary.dropdown-toggle:focus,.search-control-border .show > .btn-secondary.dropdown-toggle{color: #495057;background-color:#fff;border-color:#80bdff;outline: 0;-webkit-box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);}
.form-group .Select.is-open > .Select__control{border-radius: .25rem;}
.Select__control .Select-input{padding-left: 0;}
.signup-bx .btn-bluedark,.confirm-your-identity .btn-bluedark{width: 100%;font-size: 18px;font-weight: 600;margin-top:15px;} 
.signup-bx .btn-goback {width: 20%;font-size: 18px;font-weight: 600;margin-top:15px;} 
.link-login{font-size: 14px;color: #323232;margin-top: 30px;}
.lgn-link-login{font-size:16px; font-weight:600; color:#020202;	margin-top: 30px;}
.lgn-link-login a{color: #4a7ea6;}
.link-login a{color: #3890da;}
.forgot-pass{font-size: 15px;color: #3890da;}
.maching-profile{padding-bottom: 30px;}
.profile-header{background-color: #fff;text-align: center;padding-top:35px;padding-bottom:35px;color: #323232;margin-bottom: 30px;}
.profile-header h4{font-size: 24px;font-weight:600;margin-bottom: 10px;}
.profile-header p{font-size: 16px;font-weight: 300;}
.profile-details .text-blue{font-size: 18px;}
.profile-details .btn-bluedark{font-size: 18px;font-weight: 600;padding: 7px 23px;margin-left: 10px;}
.maching-bx{background-color: #fff;-webkit-box-shadow:0px 17px 25px 0px rgba(0, 0, 0, 0.1);-moz-box-shadow:0px 17px 25px 0px rgba(0, 0, 0, 0.1);box-shadow:0px 17px 25px 0px rgba(0, 0, 0, 0.1);margin-top: 30px;padding: 30px;border-radius: 6px;}
.profile-info{margin-left: 112px;color: #323232;padding-top: 12px;}
.profile-info h3{font-size: 22px;font-weight: 700;padding-top:5px;margin-bottom:8px;}
.profile-info h6{font-size:14px;margin: 0;}
.maching-bx .sprite{width: 16px;height: 14px;position:absolute;left:auto;margin-left: -25px; top:4px;margin-right:5px;}
.maching-bx p{font-size: 14px;color: #3c3c3c;margin-bottom:10px;position: relative;padding-left:25px;}
.maching-bx .img-bx{width: 85px;height: 85px;}
.rbt-menu-custom-option .rbt-highlight-text{position: relative;}
.rbt-menu-custom-option .dropdown-item{white-space: normal;}
.rbt-menu-custom-option .rbt-highlight-text::before,.rbt-menu-custom-option .rbt-highlight-text::after{content: '"';}
.btn-right{text-align: right;}
.btn-profile{background-color: #dfebf6;border:none;font-size: 14px;font-weight: 600;color: #2a6a8c;border-radius: 5px;padding:6px 28px;}
.btn-profile:hover{background-color: #4a98d2;color: #fff;}
.btn-blue{background-color: #5bbaff;color: #fff;font-weight: 700;font-size: 1rem;-webkit-box-shadow:0px 4px 6px 0px rgba(0, 0, 0, 0.11);-moz-box-shadow:0px 4px 6px 0px rgba(0, 0, 0, 0.11);box-shadow:0px 4px 6px 0px rgba(0, 0, 0, 0.11);border-radius: 6px;padding:14.5px 31px;margin: 25px 0;border: none;}
.btn-blue:hover{color: #fff;background-color: #1370b3;}
.full-container{max-width:100%;padding-left: 0px;overflow-x: hidden;padding-right: 0px;background: url(images/body-bg.jpg) no-repeat;height: 100vh;background-attachment: fixed;background-size: cover;min-height: 625px }
.brokers-list-bg{background: url(images/broker-list-bg.jpg) no-repeat;background-attachment: fixed;background-size: 100% 100%;min-height: 625px}
.full-container .header{background-color: rgba(33,33,33,.65)}
.full-container .header .navbar-brand{position: relative;z-index: 2;}
.full-container .header .navbar{position: relative;padding-top: 0;padding-bottom: 0;}
.page-heading{position: absolute;left: 0;right: 0;top: 0;text-align: center;color: #fff;font-size: 26px;font-weight: 400;padding:15px 230px;margin: 0;}
.stepform{padding: 1rem 0;height:calc(100vh - 75px);}
.full-container.brokers-list-bg .stepform{height:auto;}
.content-bx{width: 100%;max-width: 810px;border-radius: 12px;-webkit-box-shadow: 0px 17px 25px 0px rgba(122, 135, 157, 0.15);-moz-box-shadow:0px 17px 25px 0px rgba(122, 135, 157, 0.15);box-shadow:0px 17px 25px 0px rgba(122, 135, 157, 0.15);margin:0px auto;background-color: #fff;}
.border-bx{border: 1px solid #ececec;border-radius:12px 12px 0 0;border-bottom: 0;padding-bottom:30px}
.begin-bx{margin:35px 35px 0;background-color: #4a96d0;border-radius: 12px;padding:57px 50px;text-align: center;}
.begin-bx p{font-size:24px;margin-top: 35px;font-weight: 300;color: #fff;line-height: 28px;}
.form-bx .small{color:#5d5d5d;}
.footer-bx{border: 1px solid #d8d8d8;background-color: #eaeaea;border-radius:0 0 12px 12px;padding: 10px 0px;text-align: center;}
.content-header{border: 1px solid #4a96d0;background-color: #4a96d0;border-radius:12px 12px 0 0 ;padding:14px 0px;text-align: center;}
.content-header h4{font-size: 20px;color: #fefefe;font-weight: 400;margin: 3px 0;}
.content-header h4 small{font-size:18px;}
.btn-bluedark,.show-matches{background-color: #1e5e90;color: #fff;font-weight:600;font-size:18px;border-radius: 6px;padding:10.5px 44px;border: none;}
.lgn-btn-bluedark{background-color: #fff;color: #3c3c3c;border-radius: 6px;border: 1px solid #c1c1c1;cursor: pointer;}
.lgn-btn-bluedark p{
	font-size:18px;
	font-weight:600;
	color: #020202;
	line-height: 14px;
	text-align: center;
	margin:4px auto 0px;
}
.lgn-btn-bluedark strong{
	font-size: 24px;
	font-weight: bolder;
}

.btn-whitedark{background-color: #fff;color: #3c3c3c;font-weight:400;font-size:16px;border-radius: 6px;padding:.2rem 1rem;border: none;}
.btn-whitedark:hover{color: #3c3c3c;background-color: #fff;}
.show-matches{padding:9px 20px;}
.btn-bluedark:hover,.show-matches:hover,.btn-upload:hover{color: #fff;background-color: #0e4570;}
.btn-bluedark:focus,.show-matches:focus,.btn-green:focus,.btn-bordered:focus,.custom-menu-category .btn-secondary.focus, .btn-default:focus,.custom-menu-category .custom-control-input:focus ~ .custom-control-label::before,.profile-modal .btn-toggle:focus,.profile-modal .btn-toggle:active:focus{-webkit-box-shadow: none;-moz-box-shadow:none;box-shadow:none;}
.form-bx{border: 1px solid #ececec;border-top: 0;border-bottom: 0;height:340px;position: relative;}
.error-class {position: absolute;bottom: -16px;right: 15px;left: 15px;}
.btn-prev{background: none;border: none;color: #a9a9a9;font-size: 18px;height: 48px;border-radius: 6px;padding:10.5px 31px;}
.btn-prev + .btn{margin-left: 20px;}
.full-container .slick-slide{opacity: 0.7;float: left;}
.full-container .slick-current{opacity: 1!important;}
.type-of-business{padding:0px 30px;}
.type-of-business .form-check-label{display: block;height: 100%;background-color: #000;border-radius: 6px;padding:14px 2px;text-align: center;font-size: 14px;color: #fff;opacity: 0.8;}
.type-of-business .position-relative:hover .form-check-label{opacity: 1;}
.type-of-business .col-md-3{margin:12.5px 0;}
.type-of-business .form-check-input{opacity: 0;position: absolute;top:0;bottom:0;width:100%;height:100%;z-index:10;margin: 0;left: 0;right: 0;cursor: pointer;}
.type-of-business .form-check-input:checked ~ .form-check-label{background-color: #4da1e7;background:-moz-linear-gradient(0deg, rgba(148,205,252,1) 0%, rgba(74,150,208,1) 52%, rgba(19,137,227,1) 100%);background:-webkit-gradient(linear, left top, right top, color-stop(0%, rgba(148,205,252,1)), color-stop(52%, rgba(74,150,208,1)), color-stop(100%, rgba(19,137,227,1)));background:-webkit-linear-gradient(0deg, rgba(148,205,252,1) 0%, rgba(74,150,208,1) 52%, rgba(19,137,227,1) 100%);background:-o-linear-gradient(0deg, rgba(148,205,252,1) 0%, rgba(74,150,208,1) 52%, rgba(19,137,227,1) 100%);background:-ms-linear-gradient(0deg, rgba(148,205,252,1) 0%, rgba(74,150,208,1) 52%, rgba(19,137,227,1) 100%); background: linear-gradient(90deg, rgba(148,205,252,1) 0%, rgba(74,150,208,1) 52%, rgba(19,137,227,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#94cdfc', endColorstr='#1389e3',GradientType=1 );-webkit-box-shadow: 0px 16px 25px 0px rgba(77, 173, 253, 0.45);-moz-box-shadow:0px 16px 25px 0px rgba(77, 173, 253, 0.45);box-shadow:0px 16px 25px 0px rgba(77, 173, 253, 0.45);opacity: 1;}
.form-bx .custom-control-label{width: 280px;margin:4.5px 0;border: 2px solid #b8bac1;border-radius: 21px;padding:8px 8px 8px 40px;display: inline-block;font-size: 16px;color: #5d5d5d;text-align: left;cursor: pointer;}
.form-bx .custom-control-label::before{left:20px;top:13px;width:12px;height:12px;border: 2px solid #b8bac1;background-color: transparent;border-radius: 50%;}
.form-bx .custom-control-label::after{left:20px;top:13px;width:12px;height:12px;border-color:transparent;border-radius: 50%;}
.form-bx .custom-control-input:checked ~ .custom-control-label::after{background: #fff;}
.form-bx .custom-control-input:checked ~ .custom-control-label::before {color: #fff;background-color: #fff;
}
.form-bx .custom-control-input:focus ~ .custom-control-label::before,.write-review .custom-control-input:focus ~ .custom-control-label::before,.contact-broker .custom-control-input:focus ~ .custom-control-label::before{-webkit-box-shadow:none;box-shadow:none;}
.form-bx .custom-control-input:checked ~ .custom-control-label{background-color: #4a96d0;border-color: transparent;color: #fff;}
.form-bx.buybusiness-option input[name="radio7"] ~ .custom-control-label,
.form-bx.buybusiness-option input[name="radio8"] ~ .custom-control-label{margin:13px 0;}
.form-bx .form-control{border:2px solid #b8bac1;border-radius: 5px;font-size: 18px;color: #5d5d5d;height: 64px;padding: 8px 25px;display: inline-block;width:100%;max-width: 555px;}
.form-bx #massage{height:220px;font-weight: 400;resize: none;}
.form-bx .form-group{margin:0 auto 20px;position: relative;max-width: 555px;width: 100%;}
.form-bx .form-group .sprite{width: 16px;height: 19px;position:absolute;left:15px;top:20px;}
.form-bx .form-group .form-control{padding-left: 45px;}
.bubble-heading{width: 100%;max-width: 480px;margin:10px auto 20px; background-color: rgba(30,94,144, .65);font-size: 18px;color: #fff;text-align: center;line-height: 24px;border-radius: 6px;padding: 10px 25px;}
.form-step.broker-list .content-bx{max-width: 570px;margin-bottom: 30px}
.form-step.search-result{clear: both;}
.search-result .form-bx{padding:30px;height:inherit;border: none;color: #fff;}
.search-result h3{font-size: 34px;font-weight: 300;margin: 3px 0;}
.search-result .begin-bx{margin: 0;padding:31px 15px;}
.text-blue{color: #3890da;}
.search-result .begin-bx p{font-size: 24px;margin-top:0px;line-height: normal;margin-bottom: 15px;}
.search-result .begin-bx p.text-blue{margin-top: 20px;}
.search-result .begin-bx p em{font-weight: 400;}
.search-result .sprite{width: 19px;height: 19px;margin-top: -2px;}
.search-result .d-inline-block{color: #fff;font-size: 22px;margin: 0px 10px;min-width:221px;text-align:left;}
.search-result .d-inline-block a{color: #fff;}
.search-result .d-inline-block a:hover{color: #000;}
.bg-white.loader{background-color: #fff;}
.loader {position: fixed;left: 0;right: 0;top: 0;bottom: 0;background:rgb(245, 246, 247) ;z-index: 100;}
.spiner {border: 4px solid #f3f3f3; /* Light grey */border-top: 4px solid #3498db; /* Blue */border-radius: 50%;width: 56px;height:56px;animation: spin 2s linear infinite;position: absolute;left: 50%;top: 50%;margin: -28px 0 0 -28px;}
.loader h3 {position: absolute;left: 0;right: 0;text-align: center;top: 50%;margin-top: -100px;font-weight: 600;}
@keyframes spin {
	0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }
}
.progressbar{margin:23px auto 0px; counter-reset: step;padding: 0;width:100%;max-width: 910px;opacity: 0;}
.progressbar li {list-style-type: none;width: 14.2857%;float: left;margin-top: -10px;position: relative;text-align: center;}
.progressbar li:before {width: 30px;height: 30px;content: counter(step);counter-increment: step;line-height:26px;background-color: #000;border:2px solid rgba(225,225,225,0.35);display: block;text-align: center;margin: 0 auto 10px auto;border-radius: 50%;color: #fff;font-size: 18px;position: relative;	font-weight: 700;z-index: 1;}
.progressbar li:after {width: 100%;height: 7px;content: '';position: absolute;background-color: rgba(255, 255, 255, 0.25);border-radius: 4px;top: 10px;left: -50%;}
.progressbar li:first-child:before,.progressbar li.active:before {background-color: #4a96d0;}
.progressbar li:first-child:after {content: none;}
.progressbar li.active {color: green;}
.progressbar li.active:before {border-color: #4a96d0;}
.progressbar li.active:after {background-color: #4a96d0;}
.form-bx .geosuggest{width: 90%;max-width: 555px;margin:0 auto;position: relative;}
.geosuggest__suggests-wrapper{position: absolute;left: 0;right: 0;z-index:10;}
.geosuggest__suggests--hidden {max-height: 0;overflow: hidden;border-width: 0;margin: 0;}
.geosuggest__suggests-wrapper ul:empty{display: none;}
.geosuggest__suggests-wrapper ul:not(.geosuggest__suggests--hidden){list-style: none;text-align: left;padding: 0;margin: 0;border-bottom: honeydew; border-left: honeydew; border-right: honeydew; border-top: 1px solid #e6e6e6; box-shadow: 0 2px 4px rgba(0,0,0,0.2); border-radius: 0 0 2px 2px;background-color: #fff;margin-top:4px;}
.geosuggest__suggests-wrapper ul li{padding: 8px;text-align: left;background-color: #fff;cursor: pointer;}
.geosuggest__suggests-wrapper ul li:hover{background-color: #fafafa;}
.geosuggest__suggests-wrapper ul li.geosuggest__item--active {background-color: #4b9bde;color: #fff;}
.search-input-container{position: relative;}
.message-header-link {
	position: relative;
}
.message-badge {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 45px;
	width: 20px;
	height: 20px;
	padding: 4px 8px;
	background-color: rgb(77, 143, 213);
	position: absolute;
	right: 13px;
	top: 13px;
	font-size: 10px;
	color: rgb(255, 255, 255);
	border: 2px solid #282828;
}
.message-badge.burger-view {
	display: none;
}
@media (max-width: 540px) {
	.message-badge {
		top: -2px;
		right: -8px;
	}
	.message-badge.burger-view {
		top: -2px;
		right: -8px;
	}
}
@media (max-width: 991px) {
	.navbar-toggler {
		padding: 0 15px;
	}
	.message-badge.burger-view {
		display: inline-flex;
		top: -2px;
		right: 5px;
	}
}
#root {
	overflow: hidden;
}

@media (max-width: 990px) {
	#root {
		padding-top: 120px;
	}
	.navbar_header {
		position: fixed!important;
		width: 100vw;
	}

}
.selected-lead {
	background-color: #eee!important;
}
.selected-lead > td {
	background-color: #eee!important;
}

.rbt-input.rbt-input-multi .rbt-input-wrapper {
	align-items: center;
}
.btn.btn-secondary.dropdown-number-toggle {
	padding: 0;
	width: 100%;
}
.btn.btn-secondary.dropdown-number-toggle:hover,
.btn.btn-secondary.dropdown-number-toggle:focus,
.btn.btn-secondary.dropdown-number-toggle:active {
	padding: 0;
	box-shadow: none;
	outline: none;
	background-color: transparent;
}
.lead-img-wrapper {
	display: flex;
	position: relative;
	height: 120px;
	width: 120px;
	align-self: center;
	margin: auto;
}
.linkedin-logo-link {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 3px;
	border-radius: 50%;
	background-color: #3890da;
	width: 40px;
	height: 40px;
	color: #FFF;
	display: flex;
	align-items: center;
	justify-content: center;
}
.toast-bar .Toastify__toast-body + div i {
	color: #757575;
	position: absolute;
	right: 10px;
}
.toast-bar .Toastify__toast-body + div {
	color: transparent;
}
.toast-bar {
	margin-bottom: 70px!important;
}

.clear-button,.clear-button:active,.clear-button:focus{position: absolute;right: 16px;top: 50%;transform: translateY(-50%);background: transparent;border: none;outline: none;font-weight: 600;color: #999;}
.suggestion-item{padding: 8px;text-align: left;background-color: #fff;cursor: pointer;}
.suggestion-item--active{background-color: #fafafa;}
.error-message {color: red;}
.error-class:empty{display: none;}
.error{font-size: 14px; color: red !important; display: block; padding: .5em;}
.error:empty{display:none;}
.stepform .slick-slide img{display: inline-block;width: auto;height: auto;}
.border-1{border: 1px solid #ececec;border-radius:12px;position: relative;overflow: hidden;}
.broker-details{margin:20px;}
.broker-details .btn-blue-border{display: inline-block;background-color: #1e5e90;color: #fff;border-color:#1e5e90;padding: 6px 16px;position:absolute;right: 0;top: 0;}
.broker-details .btn-blue-border:hover{color: #fff;background-color: #0e4570;border-color:#0e4570;}
.img-bx{float: left;width:65px;height: 65px;}
.broker-info{margin-left:75px;}
.broker-header{position: relative;padding-right: 140px;margin-bottom: 15px;}
.broker-info h3{font-size: 24px;color: #000;font-weight: 700;margin-bottom:8px;}
.broker-info h3 a{color: #000;}
.broker-info h3 a:hover{color: #4a98d2;}
.btn-blue-border{background-color: #e3edf6;border: 1px solid #c6d8e7;font-size: 14px;font-weight: 600;color: #519cd1;border-radius:4px;padding:6px 28px;margin-top: 15px;}
.btn-blue-border:hover{background-color: #4a98d2;color: #fff;border: 1px solid #4a98d2;}
.broker-info p{position:relative;padding-left:20px;margin-bottom: 5px;font-size: 16px;color: #3c3c3c;line-height:20px;}
.broker-info .sprite{width: 15px;height: 14px;position:absolute;left:auto; margin-left: -20px; top:2px;}
.broker-details label{color: #000;font-size: 16px;font-weight: 600;}
.broker-details .form-control{border:1px solid #b8bac1;border-radius: 5px;font-size: 14px;color: #5d5d5d;padding: 8px 15px;height:85px;font-weight:300;resize: none;}
.btn-blue-bordered{font-size: 14px;font-weight: 600;color: #1e5e90;border-radius: 5px;border:1px solid #1e5e90;background-color: #fff;padding: .375rem 20px;}
.btn-blue-bordered:hover{background-color: #1e5e90;color: #fff;}
.btn-more{border-radius: 5px;color: #fff;border:1px solid #fff;padding:11px 68px;font-size: 18px;font-weight: 600;margin-bottom: 15px;display: inline-block;}
.btn-more:hover{background-color: #fff;border:1px solid #fff;color: #1e5e90;}
.profile-bg{padding: 20px 0;}
#loaderprofile {left: -1px;top: -1px;right: -1px;bottom: -1px;}
.profile-bx{border-radius:6px;border: 1px solid #dadada;-webkit-box-shadow:0px 3px 7px 0px rgba(0, 0, 0, 0.1);-moz-box-shadow:0px 3px 7px 0px rgba(0, 0, 0, 0.1);	box-shadow:0px 3px 7px 0px rgba(0, 0, 0, 0.1);background-color: #fff;margin-bottom: 30px;position: relative;}
.profile-banner{margin: -1px -1px 0;background-color: #ffffff;position: relative;border-radius: 6px 6px 0 0;margin-bottom: 125px;}
.profile-banner .overlay{background: linear-gradient(rgba(0,0,0,0.45) 18%, rgba(0, 0, 0, 0)); }
.opacity-5{opacity: 0.5;border-radius: 6px 6px 0 0;}
.overlay{position: absolute;left:0;top: 0;bottom: 0;right: 0;}
.change-profile-banner{position:absolute;right:0;margin:25px;}
.change-profile-banner span{color: #fff;font-size: 14px;cursor: pointer;text-shadow: 1px 1px 10px #000;}
.logo-watermark{margin:25px;position:absolute;right:0;bottom: 0;}
.profile-pic{background-color: #fff;border:3px solid #fff;-webkit-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.25);-moz-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.25);box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.25);width: 183px;height: 183px;position: absolute;border-radius: 50%;left:30px;top:100%;margin-top: -90px; text-align: center;line-height: 173px;}
.pic-overflow{overflow: hidden;width: 177px;height: 177px;border-radius: 50%;max-width:100%;max-height:100%;}
.overflow-hidden{max-height: 200px;overflow: hidden;}
.btn-camera{position: absolute;right:5px;background-color:#fff;line-height:normal;border:3px solid #fff;-webkit-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.25);-moz-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.25);box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.25);width:35px;height:35px;border-radius:50%;top:130px;padding: 0;}
.btn-camera:hover,.btn-camera:focus{background-color: #fff;border-color: transparent;}
.profile-form{padding-left: 30px;padding-right: 30px;}
.profile-form label{font-size: 16px;font-weight: 700;color: #5d5d5d;}
.profile-form label.font-size-sm{font-size: 14px;font-weight: 400;}
.profile-form .form-control::-webkit-input-placeholder{color:rgba(48, 48, 48, 0.5);}
.profile-form .form-control::-moz-placeholder{color:rgba(48, 48, 48, 0.5);}
.profile-form .form-control:-ms-input-placeholder{color:rgba(48, 48, 48, 0.5);}
.profile-form .form-control:-moz-placeholder {color:rgba(48, 48, 48, 0.5);}
.selected-categories{border: 1px solid #e1e1e1;background-color: #f6f8fa;margin-top: 15px;border-radius: 6px;padding: 15px;}
.selected-categories p{font-size: 14px;color: #5d5d5d;margin: 0;}
.selected-categories p + p{margin-top: 10px;}
.selected-categories .btn-danger{border: none;background-color: #ec543b;border-radius: 3px;opacity: .4;font-size: 12px;padding: 3px 20px;}
.selected-categories .btn-danger:hover{opacity: 1;}
.profile-form .btn-success{color: #fff;font-weight:600;font-size:16px;border-radius: 6px;padding:9px 47px;border: none;}
.profile-form .btn-success:hover{background-color: #379403;}
.btn-plus{background-color: #fafafa;border: 1px solid #c1c1c1;padding: 6px 15px;height: 45px;border-radius: .25rem;}
.btn-plus:hover,.btn-plus:focus,.btn-plus:active{background-color: #e5e5e5!important;border: 1px solid #c1c1c1;}
.control-bx p{font-size: 14px; color: #303030;font-weight: 300;line-height: 24px;}
.btn-upload{background-color: #4a98d2;color: #fff;font-weight:600;font-size:16px;border-radius: 6px;padding:9px 47px;border: none;width: 100%;max-width: 330px;}
.link-download{color:#4a98d2;text-decoration: underline;}
.btn-submit{background-color: #4a98d2;color: #fff;font-weight:600;font-size:16px;border-radius: 6px;padding:9px 65px;border: none;}
.testimonials{padding: 0 30px;}
.testimonialslist{color: #3c3c3c;position: relative;padding: 15px 50px 15px 0px;border-top: 1px solid #edeeef;}
.testimonialslist p{margin-bottom: 5px;font-size: 14px;line-height: 24px;}
.testimonialslist .i-edit{position: absolute;right:25px;top: 30px;}
.testimonialslist .i-delete,.review-usercomment .i-delete{position: absolute;right: 0;top: 30px;}
.listing-details .i-delete{position: absolute;right: 0;top: 25px;}
.listing-details .i-edit{position: absolute;right:25px;top: 25px;}
.link-more{font-size: 13px;color: #60a1df;}
.link-more .sprite{margin-left: 5px;}
.certificationslist{padding-left: 16px;}
.certificationslist li{font-size: 16px;}
.certificationslist li a{display: inline-block;color: #4a98d2;padding: 0px 30px 10px 0px;position: relative;vertical-align: top}
.certificationslist li .sprite{opacity: 0.8;position: absolute;top:3px;right: 5px;}
.certificationslist li a:hover .sprite{opacity:1;}
.selected-location{border: 1px solid #c1c1c1; border-radius: 10px; background-color: #ececec;color: #6D7277;display: inline-block;font-size:14px;line-height: 1.4;margin-right: 5px;margin-top: 5px;vertical-align: top;border-spacing:0;border-collapse:separate;position:relative;padding-left: 22px;}
.selected-location .form-check-input{opacity: 0;position: absolute;}
.location-value-icon{cursor: pointer;border-bottom-left-radius: 2px;border-top-left-radius: 2px;border-right: 1px solid #ececec;padding: 1px 5px 3px;vertical-align:middle;font-size: 0.9em;line-height: 1.4;position:absolute;left:0;bottom:0;top:0;}
.location-value-icon:hover, .location-value-icon:focus {background-color: #d8eafd;background-color: rgba(0, 113, 230, 0.08);color: #0071e6;}
.location-value-label{border-bottom-right-radius: 2px;border-top-right-radius: 2px;cursor: default;padding:2px 5px 2px 0px;vertical-align:middle;color: #6D7277;font-size: 0.9em;line-height: 1.4;display: inline-block;}
.Select__value-container--is-multi .Select__multi-value{color: #3890da;margin-left: 0;margin-right: 5px;}
/* .modal-dialog-centered .dropzone, .dropzone{} */
.drop-n-crop{margin: 30px 0}
.listing-modal .btn-close {
	position: absolute;
	right: 20px;
	top: 20px;
}
#transactionmodal .btn-close {
	position: absolute;
	right: 20px;
	top: 20px;
}
#transactionmodal .modal-title {
	margin: 0;
}
#transactionmodal .col-form-label {
	text-align: end;
}
#transactionmodal .input-group-text {
	-webkit-border-radius: 25px 0 0 25px;
	-moz-border-radius: 25px 0 0 25px;
	border-radius: 25px 0 0 25px;
}
.profile-modal .drop-n-crop{margin: 0;}
.img-preview{background-color: #fafafa;border: 1px solid #c1c1c1;border-radius: 4px;height: 140px;padding: 15px;line-height: 110px;text-align: center;}
.img-preview .img-fluid{max-height: 100%;}
.full-size{max-width:1075px;}
.banner-selection{margin-top: 20px;position: relative;}
.banner-selection img{border-radius: 4px;width: 100%;}
.banner-selection .custom-radio {float:right;margin:25px;}
.banner-selection .custom-control-input,.banner-selection .custom-control-label{position: absolute;right: 0;}
.banner-selection .custom-control-label{width: 30px;height: 30px;}
.banner-selection .custom-control-label::before{width: 28px;height: 28px;background-color:transparent;border: 2px solid #fff;top: 0;left: 0;}
.banner-selection .custom-control-label::after{top: 0;left: 0;width:28px;height: 28px;background-color:transparent;}
.banner-selection .custom-radio .custom-control-input:checked ~ .custom-control-label::before{background-color:transparent;}
.banner-selection .custom-radio .custom-control-input:checked ~ .custom-control-label::after{background-color:transparent;border-radius: 50%;background-position:-239px -23px;}
.reviews-list{border-top: 1px solid #edeeef;padding:20px 0 10px;}
.review-userpic{float: left;width: 120px}
.reviews-list .rounded-circle{border:3px solid #fff;-webkit-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.25);-moz-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.25);box-shadow:0px 3px 6px 0px rgba(0, 0, 0, 0.25);}
.review-usercomment{margin-left: 135px;font-size: 14px;position: relative;/*padding-right: 30px;*/}
.review-usercomment h5{font-size: 16px;color: #303030;font-weight: 600;}
.review-usercomment h5 .sprite{margin-right:3px;width:13px;height:13px;margin-top: -5px;}
.review-usercomment p{font-weight: 300;color: #3c3c3c;line-height: 21px;margin-bottom:10px;}
.review-usercomment p strong{font-weight: 700;}
.review-usercomment p.text-lighted{font-size: 12px;color: #a0a0a0;}
.review-usercomment p.text-blue{color: #3890da;font-weight:400;}
.review-usercomment p a{color:#60a1df;}
.review-usercomment p a:hover{color: #3890da;}
.toggle-link{border-top: 1px solid #edeeef;padding-top:15px;}
.toggle-link .link-more{font-size: 13px;color: #60a1df;}
.toggle-link .link-more:hover,.link-add:hover{color: #3890da;}
.profile-modal{max-width:750px;}
.profile-modal .modal-body.plr-0{padding-left: 0;padding-right: 0;}
.profile-modal .btn-toggle{background-color: #eee;border:none;width: 100%;font-size: 18px;color: #000;margin: 5px 0;font-weight: 600;text-align: left;}
.profile-modal .btn-toggle:active{background-color: #eee !important;color: #000!important;}
.arrow-right {width: 0; height: 0;border-top: 6px solid transparent;border-bottom: 6px solid transparent;border-left: 8px solid #000;border-right: 0;margin-top: 0px;margin-left: 0px;}
[data-class*="active"] .arrow-right{transform: rotate(90deg);}
.profile-modal .row.form-group {margin-right:-5px;margin-left: -5px;}
.profile-modal .row.form-group .col-form-label{text-align: right;padding-top: 15px;}
.profile-modal .row.form-group [class*="col-md"]{padding-left: 5px;padding-right: 5px;}
.profile-modal .modal-body{padding:15px 30px;}
.profile-modal .modal-title{font-size: 23px;font-weight: 600;margin-top: 20px;}
.profile-modal .nav-tabs .nav-item{margin-right: 10px;}
.profile-modal .nav-tabs .nav-item:last-child{margin-right: 0;}
.profile-modal .nav-tabs{border-bottom:none;}
.profile-modal .nav-tabs .nav-link{background-color: #e3edf6;border:1px solid #c6d8e7;font-size: 16px;color: #3890da;padding:9.5px 25px;border-radius: 4px 4px 0 0;cursor: pointer;}
.profile-modal .nav-tabs .nav-link:hover{color: #3890da;}
.profile-modal .nav-tabs .nav-link.active{background-color:#3890da;color: #fff;border-color: #3890da;}
.profile-modal .tab-pane{border: 1px solid #e1e1e1;padding: 20px;border-radius:0 0 6px 6px;}
.profile-modal .tab-pane p{color: #5d5d5d;line-height: 19px;font-size: 14px}
.profile-modal .tab-pane p.text-center{font-size: 16px}
.profile-modal .tab-pane p.text-center.font-size-18{font-size: 18px}
.profile-modal label{font-size: 14px;font-weight: 400;color: #000;}
.profile-modal .row.form-group label.text-right.col-form-label{padding-top: 15px;}
.link-add{color: #3890da;font-size: 14px;text-decoration: underline;}
.client-list{padding-left: 16px;}
.client-list li{font-size: 16px;font-weight: 300;}
.client-list li a {display: inline-block;color: #000000;padding: 5px 30px 5px 0px;position: relative;}
.client-list li .sprite{opacity: 1;position: absolute;top:8px;right: 5px;}
.client-list li a:hover .sprite{opacity:1;}
#massagebody.form-control{resize: none;height:165px!important;}
.profile-modal .btn-bluedark,.lgn-btn-bluedark{width: 100%;max-width: 495px;margin:30px auto 15px;display:block;font-size: 18px;font-weight: 600;}
.profile-modal .lgn-btn-bluedark{width: 100%;max-width: 495px;margin:0px auto 0px;display:block;font-size: 18px;font-weight: 600;}
.profile-modal .btn-bluedark.max-300{max-width: 300px}
.profile-modal .btn-bluedark.max-inherit{max-width:inherit;}
.text-small{font-size: 14px;color: #5d5d5d;font-weight: 300;line-height: 19px;}
.profile-modal .react-datepicker-wrapper,.profile-modal .react-datepicker__input-container{display: block;}
.profile-modal .react-datepicker__input-container{background-color: #fafafa;}
.profile-modal .react-datepicker-wrapper .form-control{position: relative;z-index: 1;background-color:transparent;}
.profile-modal .react-datepicker__input-container::after{background-position:-36px -58px;width:22px;height:24px;position: absolute;content: '';top:10px;right: 15px;}
/* .profile-modal .input-group-text{background-color:#fafafa;font-size:14px;font-weight:700;color:#000;border-color: #c1c1c1;padding-left:20px;padding-right:20px;} */
.upload-file h5{font-size: 16px;color: #333;font-weight: 600;margin:10px 0 15px}
.upload-file .table-sm td,.upload-file .table-sm th{padding:4px 3px;color: #333;font-weight: 400;border-color: #c5c5c5;}
.upload-file .table-sm th{font-size: 11px;}
.upload-file .table-sm td{font-size: 10px;}
.upload-file .table-sm th{border-bottom-width: 1px;background-color: #adadad;padding:8px 5px;}
.bg-yellow td{background-color: #fee984;}
.upload-file .form-control-file{-ms-flex: 1 1 auto;flex: 1 1 auto;width: 1%;border: 1px solid #c1c1c1;background-color: #fafafa;border-radius: .25rem 0 0 .25rem;padding: 10px 15px;}
.profile-modal .upload-file .btn-bluedark{margin: 0;font-size: 16px;padding: 9px 35px;}
.table.tbl-transaction thead th{border-bottom-width: 1px;font-size: 15px;font-weight: 600;color: #000;padding: 15px 5px;}
.table.tbl-transaction td, .table.tbl-transaction th{border-color:#edeeef;}
.table.tbl-transaction td{padding: 10px 5px;color: #303030;font-size: 13px;}
.control-bx p.sold-info{position: relative;padding-left: 15px;margin-bottom:0px;font-size: 13px;color: #6799cb;font-weight: 400;line-height: 21px;margin-top: 5px;}
.control-bx .table-responsive{margin-top:30px;}
.caret-bottom{position: absolute;right: 0;top: 0;width: 30px;height: 39px;}
.caret-bottom::before{content: '';border-left:5px solid transparent;border-right:5px solid transparent;border-top: 6px solid #7d7d7d;position: absolute;top:50%;margin-top:-3px;left:50%;margin-left:-5px;}
.caret-bottom.up-arrow::before{content: '';border-right: 5px solid transparent;border-bottom: 5px solid #7d7d7d;border-left: 5px solid transparent;border-top: 0;}
.custom-menu-category .btn-group{width: 100%;}
.custom-menu-category .form-control{max-height: 40px;padding-right: 40px;line-height: 29px;}
.custom-menu-category .dropdown .dropdown-menu{right: 0;padding: 0;max-height:330px;overflow: auto;background-clip:border-box;}
.custom-menu-category .dropdown .dropdown-menu .custom-control{padding:0px 35px 0px 15px;border-bottom: 1px solid #e2e2e2;position: relative;font-size: 16px;}
.custom-menu-category .dropdown .dropdown-menu .custom-control.childinput{padding-left: 30px;border-bottom: 1px solid #e2e2e2;}
.custom-menu-category .dropdown .dropdown-menu .categorylist-child .custom-control.childinput{padding-left: 0;padding-right: 0;}
.custom-menu-category .dropdown .dropdown-menu .categorylist-child .custom-control.childinput label{padding-left: 55px;padding-right: 30px;}
.custom-menu-category .dropdown .dropdown-menu .categorylist-child .custom-control.childinput .custom-control-label::before{left: 35px;}
.custom-menu-category .dropdown .dropdown-menu .custom-control:hover{background-color: #fafafa;}
.custom-menu-category .dropdown-menu label{font-size:16px;font-weight:400;display: block;cursor: pointer;padding:7px 10px 7px 20px;}
.custom-menu-category .dropdown-toggle::after{position: absolute;right: 19px;top:20px;border-width: 6px 5px 2.5px;border-color: #7d7d7d transparent transparent;}
.custom-menu-category .show > .dropdown-toggle::after{border-top: 0;border-right: 5px solid transparent;border-bottom:5px solid #999;border-left: 5px solid transparent;}
.custom-menu-category .custom-checkbox .custom-control-label::before{width: 14px;height: 14px;position: absolute;left: 0;top: 12px;content: '';background-position: -271px -50px;transition:none;-moz-transition:none;-webkit-transition:none;}
.custom-menu-category .custom-checkbox.partialed .custom-control-label::before{background-position: -119px -30px;}
.custom-menu-category .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{background-position: -271px -67px;background-color: transparent}
.custom-menu-category .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{background: none;}
.custom-menu-category .selected-location{margin-top: 4px;}
.custom-menu-category .location-tags{position: relative;z-index: 3;}
.user-avatar .cropper-face,.user-avatar .cropper-view-box{border-radius: 50%;}
.public-view .profile-banner{margin-bottom:115px;}
.user-info{padding:0 30px 15px;}
.user-info h4{font-size: 24px;font-weight: 700;}
.user-info h6{font-size:18px;font-weight:400;}
.location-info{position: relative;padding-left: 18px;font-size: 16px;color: #7d7d7d;margin-bottom:30px;}
.location-info .i-locator{position: absolute;left: 0;top: 5px;}
.btn-green{font-size: 16px;font-weight: 600;padding: 10.5px 30.5px;background-color:#29be37;color: #fff;border-radius: 6px;border: none;}
.btn-green:hover{color: #fff;background-color: #0a9217;}
.user-info .btn-green{margin-right: 12px;margin-bottom: 15px;}
.user-info .btn-bordered{margin-bottom: 15px;}
.btn-bordered{font-size: 16px;font-weight: 600;padding: 8.5px 28.5px;border-radius: 6px;border:2px solid #b2b2b2;background-color: transparent;color: #5d5d5d}
.user-info .form-group{margin-bottom:8px;}
.user-info .form-group:last-child{margin-bottom: 0}
.user-info label{color: #303030;font-size: 16px;font-weight: 600;display: block;margin: 0;}
.block-text{display: block;color: #303030;font-size: 14px;font-weight:300;}
.public-view h5{font-size: 22px;font-weight:600;margin-bottom:20px;}
.editor-text ul,.editor-text ol{padding-left: 16px;}
.editor-text li{margin-bottom: 5px;;}
.editor-text blockquote {
	background: #f9f9f9;
	border-left: 10px solid #ccc;
	margin: 1rem 0;
	padding: 0.5rem 10px;
	quotes: "\201C""\201D""\2018""\2019";
  }
  .editor-text blockquote:before {
	color: #ccc;
	content: open-quote;
	font-size: 4rem;
	line-height: 0.1rem;
	vertical-align: -0.4em;
  }
  .editor-text blockquote p {
	display: inline;
  }
.map-loader{position: relative;height: 315px;}
.map-loader h6.text-blue{margin: 0}
.bx-padding,.listing-bx{padding:25px 30px;}
.bx-padding p{font-size: 14px;color: #3c3c3c;font-weight: 300;line-height: 19px;}
.bx-padding p strong{font-weight: 700;}
.listing-bx{background-color: #91a9c0;}
.public-view .listing-bx h5{color: #fff;}
.listing-bx h6{color: #edf5fc;font-size: 16px;}
.listing-listbx{background-color: #fff;-webkit-box-shadow:0px 2px 5px 0px rgba(0, 0, 0, 0.15);-moz-box-shadow:0px 2px 5px 0px rgba(0, 0, 0, 0.15);box-shadow:0px 2px 5px 0px rgba(0, 0, 0, 0.15);margin-top: 20px;padding:10px 15px 15px;}
.listing-img{float: left;width: 80px;height: 80px;margin-top: 3px;}
.listing-details{margin-left:100px;font-size: 13px;font-weight: 600;color: #5d5d5d;}
.listing-location{position: relative;padding-left: 15px;margin-bottom:10px;}
.listing-location .i-listing-locator{position: absolute;left: 0;top:3px;}
.listing-details .font-weight-light{font-size: 12px;line-height: 16px;}
.show-more{margin-bottom: 0;margin-top: 15px;}
.show-more a{font-size: 14px;color: #fff;}
.sale-listingview{margin-top:30px;}
.sale-listingview .listing-details{position: relative;padding-right:50px;}
.sale-listing{border-top: 1px solid #edeeef;padding:10px 0;}
.control-bx p.sale-text-title{font-size: 13px;font-weight: 600;color: #323232;}
.control-bx .sale-listing p{line-height: normal;}
.control-bx .sale-listing p.text-cashflow{font-size: 13px;font-weight: 600;color: #5d5d5d;}
.control-bx .sale-listing p.text-blue{color: #3890da;font-size: 13px;font-weight: 600;}
.control-bx .sale-listing p.sold-info{margin-top: 0;font-weight: 600;}
.control-bx .sale-listing .listing-details .font-weight-light{font-size: 14px;font-weight: 400;color: #5d5d5d;line-height: 21px;}
.bx-padding .selected-categories p{font-weight: 400;color: #5d5d5d;}
.bx-padding .selected-categories p strong{font-weight:700;}
.public-view .reviews-list{border-top: 0;padding-top: 0;}
.public-view .profile-bx > .reviews-list + .reviews-list,.public-view .profile-bx div .reviews-list{border-top: 1px solid #edeeef;}
.public-view .toggle-link{margin-bottom: 0;font-weight: 400;}
.public-view .profile-bx > .reviews-list + .reviews-list,.public-view .profile-bx div .reviews-list{padding-top: 20px;}
.public-view .review-usercomment,.public-view  .testimonialslist{padding-right:0;}
.public-view  .btn-sm.btn-bluedark{font-size:14px;font-weight:600;padding:7px 22px;}
.public-view  .review-usercomment h5{font-size: 16px;margin-bottom: .5rem;}
.public-view .testimonialslist{border-top: 0;padding-top: 0;}
.public-view .profile-bx > .testimonialslist +.testimonialslist,.public-view .profile-bx div .testimonialslist{padding-top:15px;border-bottom: 1px solid #edeeef;}
.bx-padding .testimonialslist p{line-height: 24px;}
.bx-padding .testimonialslist p.text-blue{color:#3890da;font-weight: 400;}
.sold-padding{padding:25px 15px;}
.public-view .profile-bx .sold-list-view > .sold-list + .sold-list, .public-view .profile-bx .sold-list-view div .sold-list{border-top: 1px solid #dadada;padding-top: 15px;}
.sold-img{float:left;width: 80px;height: 80px;margin-top: 3px;}
/*.sold-details{margin-left:100px;}*/
.sold-list h6{font-size: 14px;font-weight: 700;color: #303030;line-height: 19px;}
.sold-info{position: relative;padding-left: 20px;margin-bottom:8px;font-size: 14px;color: #6799cb;font-weight: 600;line-height: 18px;}
.sold-info .sprite{width: 16px;height: 16px;position: absolute;left:auto;margin-left: -20px; top:2px;}
.tbl-transaction .sold-info{padding-left: 15px;}
.tbl-transaction .sold-info .sprite{top:3px;width: 12px;height: 12px;}
.sold-list dl {font-size: 14px;color: #303030;margin:10px 0 10px;} 
.sold-list dl dt {float:left;width: 100px;font-weight: 600;} 
.sold-list dl dd {text-align: right;font-weight: 600;margin-bottom:3px;}
.disclaimer{font-style: italic;color: #7d7d7d;margin-top: 15px;}
.disclaimer h6{font-size: 14px;font-weight: 600;}
.disclaimer p{font-size: 12px;font-weight:300;line-height: 21px}
.write-review .profile-bx{padding: 30px;}
.write-review .profile-pic,.contact-form .profile-pic{position: static;margin: 0 auto 20px;width: 100px;height: 100px;line-height: 92px}
.write-review h4{font-size: 22px;font-weight: 700;margin-bottom:15px;}
.write-review h5{color: #000;}
.ratings{overflow: hidden;display: inline-block;vertical-align: middle;margin-left: 15px;}
.rating-input {float: right;width: 24px;height: 24px;padding: 0;margin: 0 0 0 -24px;opacity: 0;}
.rating-star,.ratings:hover .rating-star {position: relative;float: right;display: block;width: 24px;height: 24px;margin-right: 5px;background-position: -1px -84px;}
.ratings:hover .rating-star:hover,.ratings:hover .rating-star:hover ~ .rating-star,.rating-input:checked ~ .rating-star {background-position: -1px -110px;}
.write-review .profile-modal{margin:30px auto;}
.write-review .profile-modal label{font-size: 16px;}
.write-review .profile-modal label.custom-control-label{font-size: 14px; font-weight: 300;}
.write-review .custom-control{padding-left: 30px;margin-bottom: 10px;}
.write-review .custom-checkbox .custom-control-label::before{background-color: #fafafa;border: 1px solid #c1c1c1;border-radius:2px;width: 19px;height: 19px;left:-30px;top: 2px;}
.write-review .custom-checkbox .custom-control-label::after{border-radius:2px;width: 19px;height: 19px;left:-30px;top: 2px;}
.write-review .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{background-color: #3b99fc;border: 1px solid #3b99fc;}
.write-review .custom-radio .custom-control-label::before{background-color: #fafafa;border: 1px solid #c1c1c1;width: 17px;height: 17px;left:-30px;top: 2px;}
.write-review .custom-radio .custom-control-input:checked ~ .custom-control-label::before{background-color: #3b99fc;border: 1px solid #3b99fc;}
.write-review .custom-radio .custom-control-label::after{width: 17px;height: 17px;left:-29.5px;top:1.5px;}
.write-review .profile-modal .btn-bluedark{max-width: 180px;}
.write-review h3{font-size: 24px;font-weight: 600;margin-bottom:15px;}
.write-review p{font-size: 14px;color: #7d7d7d;font-weight:300;}
.write-review p.posted-review{font-size: 13px;color: #323232;font-weight:300;margin-top: 20px;}
.write-review p.text-sm{font-size:13px;color: #7d7d7d;font-weight: 300;line-height: 18px;}
.posted-review .d-inline-block{margin-top: 15px;}
p.posted-review .sprite{width:17px;height:18px;margin-top: -5px;margin-right:5px;}
.write-review hr{border-top-color: #e9e9e9;margin-bottom:30px;}
.write-review h6{font-weight: 600;color: #303030;}
.write-review div.posted-comment p{color: #3c3c3c;margin-bottom:5px;}
.write-review p.text-lighted{font-size: 13px;color: #999;margin-bottom: 20px;}
.text-link,.write-review p.text-link {font-size: 14px;font-weight: 400;}
.write-review p.text-link{color: #5d5d5d;margin-top: 5px;}
.brokers-list{margin-top: 30px;}
.brokers-list .profile-bx{padding:25px 30px;} 
.brokers-list h4{font-weight: 700;}
.brokers-list .geosuggest__input-wrapper{position: relative;}
.brokers-list .geosuggest__input-wrapper{background-color: #fafafa;}
.brokers-list .geosuggest__input-wrapper::before{background-position:-145px -58px;width:11px;height:15px;position: absolute;content: '';top:16px;left:15px;z-index:0;}
.brokers-list .geosuggest__input-wrapper .form-control{padding-left:35px;position: relative;z-index: 1;background-color:transparent;}
.brokers-list .btn-green{border-radius: 4px;padding: 10.5px 10.5px;}
.brokers-list .Select__control{min-width: 165px;height: 40px !important;padding: 2px 20px;}
.brokers-list .Select-multi-value-wrapper,.brokers-list .Select-input{display: block;min-width: 115px;}
.brokers-list .form-group .Select-placeholder{line-height: 40px;color: #5d5d5d;}
.brokers-list .form-group .Select--single > .Select__control .Select-value{padding: 2px 20px;}
.brokers-list .form-inline label{font-weight: 600;font-size:14px;color: #fff;}
.result-count{font-size: 14px;color: #fff;margin-bottom: 0;}
.matching-list{color: #5d5d5d;}
.matching-list p{margin-bottom: 0;font-size: 16px;}
.matching-list p.client-reviews{color: #303030;}
.broker-pic{width: 115px;float: left;}
.matching-list .rounded-circle{border: 3px solid #519cd1;}
.brokerlist-info{margin-left: 135px;padding-top: 10px;}
.brokerlist-info h5{font-size: 18px;font-weight: 600;color: #000;margin-bottom:5px;}
.brokerlist-info h5 a{color: #000;}
.brokerlist-info h5 a:hover{color: #4a98d2;}
.brokerlist-info p{margin-bottom:10px;font-size: 14px;line-height: 18px;}
.brokerlist-firm .briefcase{width: 15px;height: 14px;position: absolute;left: 0;top:3px;}
.matching-list p.brokerlist-firm{position: relative;padding-left: 20px;margin-bottom:8px;}
.brokerlist-location{position: relative;padding-left: 20px;margin-bottom:8px;}
.matching-list p.brokerlist-location{color: #4a98d2;font-size: 15px;line-height: 20px;}
.brokerlist-location .sprite{width: 11px;height: 15px;position: absolute;left:auto;top:3px;margin-left: -20px;}
.matching-list .btn-blue-border{display: block;margin-top: 0;padding: 7px;text-align: center;background-color: #1e5e90;border-color: #c6d8e7;color: #fff;}
.matching-list .btn-blue-border:hover{background-color: #4da1e7;background:-moz-linear-gradient(0deg, rgba(148,205,252,1) 0%, rgba(74,150,208,1) 52%, rgba(19,137,227,1) 100%);background:-webkit-gradient(linear, left top, right top, color-stop(0%, rgba(148,205,252,1)), color-stop(52%, rgba(74,150,208,1)), color-stop(100%, rgba(19,137,227,1)));background:-webkit-linear-gradient(0deg, rgba(148,205,252,1) 0%, rgba(74,150,208,1) 52%, rgba(19,137,227,1) 100%);background:-o-linear-gradient(0deg, rgba(148,205,252,1) 0%, rgba(74,150,208,1) 52%, rgba(19,137,227,1) 100%);background:-ms-linear-gradient(0deg, rgba(148,205,252,1) 0%, rgba(74,150,208,1) 52%, rgba(19,137,227,1) 100%); background: linear-gradient(90deg, rgba(148,205,252,1) 0%, rgba(74,150,208,1) 52%, rgba(19,137,227,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#94cdfc', endColorstr='#1389e3',GradientType=1 );-webkit-box-shadow: 0px 3px 13px 0px rgba(36, 102, 155, 0.45);-moz-box-shadow:0px 3px 13px 0px rgba(36, 102, 155, 0.45);box-shadow:0px 3px 13px 0px rgba(36, 102, 155, 0.45);border-color: rgba(148,205,252,1)}
.matching-list ul{padding: 0;margin: 10px -7.5px 0;list-style: none;}
.matching-list ul li{font-size: 16px;color: #323232;font-weight: 600;display: inline-block;padding:0 7.5px;}
.matching-list.search-result{padding: 15px 15px 5px;}
.matching-list.search-result h4{color: #000;}
.matching-list.search-result p{font-size: 20px;color: #5d5d5d;margin-bottom: 10px;}
.matching-list.search-result p em{font-size: 20px;color: #000;font-weight: 600;}
.content-bx span.d-inline-block { font-size: 20px; font-weight: 600;min-width: inherit;}
.content-bx span.d-inline-block i{ width:22px;height:22px;}
.matching-list.search-result .d-inline-block{font-size: 20px;font-weight: 600;min-width: inherit;}
.matching-list.search-result .d-inline-block a{color: #3890da;}
.matching-list.search-result .d-inline-block a:hover{color: #000;}
.disc{font-size: 12px;padding-right:3px;color: #999;position:relative;top:-1px;}
.banner{position: relative;color: #fff;}
.banner .img-fluid{width: 100%;}
.display-table{display: table;height: 100%;text-align: center;}
.display-cell{display: table-cell;vertical-align: middle;}
.banner h6,.deal-view .banner h6{font-size: 18px;font-weight: 700;margin-bottom:15px;color: #fff;}
.banner h1{font-size:42px;font-weight:800;margin-bottom: 0;}
.search-control{background-color: #fff;border-radius: 5px;padding: 25px 30px;-webkit-box-shadow:0px 12px 24px 0px rgba(184, 189, 192, 0.3);-moz-box-shadow:0px 12px 24px 0px rgba(184, 189, 192, 0.3);box-shadow:0px 12px 24px 0px rgba(184, 189, 192, 0.3);position: relative;z-index: 10;}
.search-control .Select,.search-control .Select-multi-value-wrapper,.search-control .Select-input{display: block;width: 100%;}
.search-control .form-group .Select__control{background-color: #fff;border:1px solid #c1c1c1;border-radius: 4px 0 0 0}
.search-control .geosuggest{width:100%;padding-top:0px;}
.search-control .geosuggest__input-wrapper{position: relative;}
.search-control .geosuggest__input-wrapper{background-color: #fff;}
.search-control .geosuggest__input-wrapper::before{background-position:-145px -58px;width:11px;height:15px;position: absolute;content: '';top:16px;left:15px;z-index:0;}
.search-control  .form-control{position: relative;z-index: 1;background-color:transparent;border: 1px solid #c1c1c1;color: #000;font-size: 14px;padding: 6px 20px 6px 20px;height:48px;border-radius:0;width: 100%;}
.search-control .geosuggest__input-wrapper .form-control{padding-left: 35px;}
.search-control .btn-bluedark{border-radius: 0  4px 0 0;text-transform: uppercase;font-size: 16px;height: 48px;}
.search-more a{color: #4a98d2;font-size: 14px;text-decoration: underline;display: inline-block;margin-top: 25px;}
/* .search-control-border{background-color: #f8fcff;border: 1px solid #c1c1c1;border-radius:0 0px 4px 4px;padding:20px 15px;border-top-width: 0;} */
.search-control-border > .row,.search-control-border > .row > div > .row{margin-left: -7.5px;margin-right:-7.5px;}
.search-control-border > .row > div,.search-control-border > .row > div > .row > div{padding-right:7.5px;padding-left:7.5px;}
/* .search-control-border .form-control{background-color:#fafafa;border: 1px solid #c1c1c1;color: #323232;font-size: 13px;padding: 6px 20px 6px 10px;height:34px;border-radius: .25rem;} */
.search-control-border .dropdown .btn-secondary.dropdown-toggle{color: #323232;text-align: left;}
.search-control-border .dropdown-toggle::after{position: absolute;right: 10px;top:14px;border-width: 6px 4px 2.5px;border-color: #7d7d7d transparent transparent;}
.search-control-border .show > .btn-secondary.dropdown-toggle::after{border-top: 0;border-right: 4px solid transparent;border-bottom:4px solid #999;border-left: 4px solid transparent;}
.search-control-border .dropdown-menu{padding-left: 10px;padding-right: 10px;border-color: #c1c1c1;width: 100%;min-width: 203px;}
.search-control-border .dropdown-menu[data-placement*=top-start]{border-radius:0.25rem 0.25rem 0 0 ;}
.search-control-border .dropdown-menu[data-placement*=bottom-start]{border-radius: 0 0 0.25rem 0.25rem;margin-top: -2px;}
.search-control-border .dropdown-menu[data-placement*=top-start] .top-start{display: none}
.search-control-border .dropdown-menu[data-placement*=bottom-start] .bottom-start{display: none}
/* .search-control-border .dropdown-menu .form-control{max-width:82px;background-color: #fff;padding: 6px;border-radius: 2px;} */
.line-hr{color: #c1c1c1;padding: 0px 2px;}
.search-control-border .custom-radio .custom-control-label::before,.search-control-border .custom-radio .custom-control-label::after{display:none}
.search-control-border .custom-control{padding-left: 0;min-height: inherit;margin:3px 0;}
.search-control-border .form-check {
	padding: 0;
}
.deal-note-modal .modal-header .btn-close:focus {
	box-shadow: none;
}
.deal-notes.btn:hover {
	color: #fff;
}
.btn.btn-unstyled:active {
	border:none;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.deal-note-modal .modal-header .btn-close {
	margin: 0;
	position: absolute;
	right: -15px;
	top: -15px;
}
.search-control-border .form-check:hover:not(.disabled) {
	background-color: #fafafa;
}
.search-control-border .form-check input{
	display: none;
}
.search-control-border .form-check label{
	font-size: 12px;color: #2b7cb8;font-weight:600;display: block;cursor: pointer;padding: 3px 10px;
}
.search-control-border .custom-control-input:disabled ~ .custom-control-label{cursor: no-drop;opacity: 0.3;background-color: transparent !important;}
.search-control-border .custom-control-label:hover{background-color:#fafafa }
.search-control .search-control-border .Select__control{background-color: #fafafa;border:1px solid #c1c1c1;border-radius: 4px;height: 34px;font-size: 14px;}
.search-control-border .Select-placeholder{color: #323232;right:10px;}
.search-control .search-control-border .Select__control .Select-input{height: 32px;}
.search-control-border .btn-link{color: #777;font-size: 13px;padding-left: 0;padding-right: 0;}



.search-control-border-edit-profile{background-color: #fff;border-radius:0 0px 4px 4px;padding:1px 1px;border-top-width: 0;}
/* .search-control-border-edit-profile > .row,.search-control-border-edit-profile > .row > div > .row{margin-left: -7.5px;margin-right:-7.5px;} */
/* .search-control-border-edit-profile > .row > div,.search-control-border-edit-profile > .row > div > .row > div{padding-right:7.5px;padding-left:7.5px;} */
.search-control-border-edit-profile .form-control{background-color:#fafafa;border: 1px solid #c1c1c1;color: #323232;font-size: 13px;padding: 6px 20px 6px 10px;height:34px!important;border-radius: .25rem;}
.search-control-border-edit-profile .dropdown .btn-secondary.dropdown-toggle{color: #aaa;text-align: left;}
.search-control-border-edit-profile .dropdown-toggle::after{position: absolute;right: 10px;top:21px;border-width: 6px 4px 2.5px;border-color: #7d7d7d transparent transparent;}
.search-control-border-edit-profile .show > .btn-secondary.dropdown-toggle::after{border-top: 0;border-right: 4px solid transparent;border-bottom:4px solid #999;border-left: 4px solid transparent;}
.search-control-border-edit-profile .dropdown-menu{padding-left: 10px;padding-right: 10px;border-color: #c1c1c1;width: 100%;min-width: 203px;}
.search-control-border-edit-profile .dropdown-menu[data-placement*=top-start]{border-radius:0.25rem 0.25rem 0 0 ;}
.search-control-border-edit-profile .dropdown-menu[data-placement*=bottom-start]{border-radius: 0 0 0.25rem 0.25rem;margin-top: -2px;}
.search-control-border-edit-profile .dropdown-menu[data-placement*=top-start] .top-start{display: none}
.search-control-border-edit-profile .dropdown-menu[data-placement*=bottom-start] .bottom-start{display: none}
.search-control-border-edit-profile .dropdown-menu .form-control{max-width:82px;background-color: #fff;padding: 6px;border-radius: 2px;}
.line-hr{color: #c1c1c1;padding: 0px 2px;}
.search-control-border-edit-profile .custom-radio .custom-control-label::before,.search-control-border-edit-profile .custom-radio .custom-control-label::after{display:none}
.search-control-border-edit-profile .custom-control{padding-left: 0;min-height: inherit;margin:3px 0;}
.search-control-border-edit-profile .custom-control-label{text-align: right;font-size: 12px;color: #2b7cb8;font-weight:600;display: block;cursor: pointer;padding: 3px 10px;}
.search-control-border-edit-profile .custom-control-input:disabled ~ .custom-control-label{cursor: no-drop;opacity: 0.3;background-color: transparent !important;}
.search-control-border-edit-profile .custom-control-label:hover{background-color:#fafafa }
.search-control .search-control-border-edit-profile .Select__control{background-color: #fafafa;border:1px solid #c1c1c1;border-radius: 4px;height: 34px;font-size: 14px;}
.search-control-border-edit-profile .Select-placeholder{color: #323232;right:10px;}
.search-control .search-control-border-edit-profile .Select__control .Select-input{height: 32px;}
.search-control-border-edit-profile .btn-link{color: #777;font-size: 13px;padding-left: 0;padding-right: 0;}





.search-results .profile-bx.matching-list{-webkit-box-shadow:none;-moz-box-shadow:none;	box-shadow:none;padding: 25px 30px;margin-top: 30px;}
.search-results  p.client-reviews{font-size: 14px;}
.anchore-link{display: block;text-align: center;font-size: 13px;color: #3890da;text-decoration: underline;margin-top: 10px;}
.result-description .btn-link{padding: 0;}
.result-description label{font-size: 13px;color: #5d5d5d;padding-top:5px;}
.result-description p{font-size: 16px;color: #323232;}
.result-description .Select__control{width: 100%;height:34px !important;padding: 2px 10px;font-size: 13px;font-weight: 600;}
.result-description .Select-multi-value-wrapper,.result-description .Select-input{display: block;min-width: 115px;}
.result-description .form-group .Select-placeholder{line-height:34px;color: #5d5d5d;font-size: 13px;font-weight: 600;padding-left:10px;}
.result-description .form-group .Select--single > .Select__control .Select-value{padding: 2px 20px;}
.result-description .Select-input{height:28px;}
.result-description .form-group .Select-arrow-zone{top: 2px;}
.gridview{margin: 0 auto 20px}
.search-results .profile-bx.search-list{padding: 15px;color: #323232;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;margin-bottom: 0;}
.search-results .col-md-4 .profile-bx.search-list{height: 100%;}
.gridview .search-list .row{height: 100%;}
.search-results .profile-bx.search-list:hover{-webkit-box-shadow:0px 3px 7px 0px rgba(0, 0, 0, 0.1);-moz-box-shadow:0px 3px 7px 0px rgba(0, 0, 0, 0.1);	box-shadow:0px 3px 7px 0px rgba(0, 0, 0, 0.1);}
.search-results .profile-bx.search-list .col-sm-5 .position-relative{min-height: 190px}
.result-category{background-color: rgba(74, 152, 210, 0.85);color: #fff;font-size: 13px;padding: 5px 15px;display: inline-block;}
.logo-watermark{margin:15px;}
.profile-banner .logo-watermark{bottom: -69px;}
.search-list h6{font-weight: 700;font-size: 16px;}
.search-list h6 a{color: #323232;}
.search-list h6 a:hover{color: #4a98d2;}
.search-list .col-sm-12 h6, .search-list .sm-offset-5.col-md-12{margin-top: 15px;}
.search-list p{font-weight: 300;font-size: 13px;color: #5d5d5d; line-height: 21px;margin-bottom: 0;}
.search-list p.brokerlist-location{font-weight:400;font-size: 14px;color: #4a98d2; line-height:22px;margin-bottom: 10px;}
.search-list ul{list-style: none;padding: 0;margin:0;}
.search-list ul li{font-size: 14px;margin-top:8px; }
.search-list .disc{padding-right:5px; }
.search-list p strong{font-size: 16px;font-weight: 700;}
.btn-default{font-size: 14px;font-weight: 600;padding: 12px;background-color:#e5e9ec;color: #5d5d5d;border-radius:5px;border: none;display: block;text-align: center;}
.btn-default:hover{background-color: #29be37;color: #fff;}
.advt-native{border-width: 3px; border-color: #e5e9ec;}
.advt-native .btn-default{background-color: #5bbaff;color: #fff;}
.advt-native .btn-default:hover{background-color: #1370b3;}
.search-results .profile-bx.search-list.advt-native .position-relative{min-height:160px;line-height: 100px}
.profile-bx.search-list .logo-watermark{line-height: 35px;}	
.border-radius-6{border-radius: 6px;}
.pagination{text-align: center !important; display: block !important;margin-bottom: 0;padding-bottom: 15px;}
.pagination li{display: inline-block;}
.pagination li + li{margin-left: 10px;}
.pagination li.disabled{opacity: 0.7;}
.pagination li .page-link{background-color: #fafafa;border: 1px solid #c1c1c1;border-radius: 4px;font-size: 16px;color: #000;font-weight:600;width: 32px;height: 32px;padding: 5px;}
.page-item.active .page-link{background-color: #1e5e90;border-color: #1e5e90;}
.marketing-banner{margin-top: 55px;margin-bottom:30px;}
.marketing-banner .sprite{width: 75px;height: 50px;}
.marketing-banner h5{font-weight: 600;color: #323232;margin-top: 15px;}
.marketing-banner p{font-size: 14px;color: #5d5d5d;line-height: 21px;}
.deal-view h4{color: #323232;font-weight: 700;}
.deal-view .featured-intermediar h4{color: #10242b;}
.deal-view h6{color: #5d5d5d;font-weight:400;}
.deal-view .slick-list{margin-left: -15px;margin-right: -15px;padding-top:30px;padding-bottom:30px;}
.deal-view .slick-slide{padding-left: 15px;padding-right: 15px;}
.franchises-bx{background-color: #fff;border-radius:6px;-webkit-box-shadow:0px 7px 18px 0px rgba(185, 189, 192, 0.5);-moz-box-shadow:0px 7px 18px 0px rgba(185, 189, 192, 0.5);box-shadow:0px 7px 18px 0px rgba(185, 189, 192, 0.5);}
.deal-view .slick-slide img{width:100%;border-radius: 6px 6px 0 0;max-height: 192px}
.franchises-details{border:1px solid #d1d1d1;border-top-width: 0;padding: 15px;border-radius:0 0 6px 6px;}
.franchises-details h6,.intermediar-details h6{font-weight: 700;color: #3890da;margin-bottom:5px;}
.franchises-details p{margin-bottom: 10px;}
.franchises-details .listing-location{line-height: 18px;font-size: 14px;color: #323232;}
.text-font-light{color: #5d5d5d;font-size: 13px;line-height: 19px;font-weight: 300;}
.capital-req{font-size: 14px;color: #323232;}
.deal-view .slick-prev,.deal-view .slick-next{width:20px;height: 32px;z-index: 1;}
.deal-view .slick-prev{left:-60px;}
.deal-view .slick-next{right:-60px;}
.deal-view .slick-prev::before,.deal-view .slick-next::before{content: "";width:20px;height: 32px;}
.deal-view .slick-prev::before{background-position: -234px -112px;}
.deal-view .slick-next::before{background-position: -263px -112px;}
.deal-view .btn-default{padding: 10.5px;}
.trending-franchise{margin-top:20px;margin-bottom: 40px;}
.trending-franchise-bx{border: 1px solid #d9dcde;background-color: #fff;border-radius: 6px;padding: 25px;}
.trending-franchise-bx p{font-size: 18px;line-height: 26px;color: #323232;}
.trending-franchise-bx .btn-blue-border{background-color: #4a98d2;color: #fff;border: 1px solid #4a98d2;display: inline-block;padding: 7.5px 30px;}
.trending-franchise-bx .btn-blue-border:hover{background-color: #0e4570;color: #fff;border: 1px solid #0e4570;}
.featured-intermediar{background-color: #f2f2f2;padding-top: 45px;padding-bottom: 45px;margin-top: 20px;margin-bottom: 40px;}
.deal-view .featured-intermediar .slick-list{padding-bottom:20px}
.intermediar-bx{background-color: #fff;border: 1px solid #d1d1d1;border-radius: 6px;padding: 15px;position: relative;margin-bottom: 30px;}
.intermediar-img{float: left;width: 137px;}
.deal-view .slick-slide .intermediar-img .img-fluid{border-radius: 6px;}
.intermediar-details{margin-left: 150px;padding-bottom: 22px;}
.intermediar-details p{line-height: 18px;margin-bottom:10px;}
.text-font-semibold{font-weight: 600;font-size: 14px;color: #323232;}
.intermediar-details .listing-location{position: absolute;bottom:5px;font-size: 14px;}
.featured-intermediar .slick-dots{bottom: 0;}
.featured-intermediar .slick-dots li,.featured-intermediar .slick-dots li button,.featured-intermediar .slick-dots li button::before{width: 21px;height: 3px;opacity: 1;}
.featured-intermediar .slick-dots li button::before{content: '';background-color: #cecece;}
.featured-intermediar .slick-dots li.slick-active button::before{background-color: #4a98d2;opacity: 1;}
.deal-details-heading{background-color: #fff;border-bottom: 1px solid #d9dcde;padding-top: 20px;padding-bottom: 10px;margin-bottom: 20px;}
.deal-details-heading h1{font-size: 24px;font-weight: 600;color: #444;margin-bottom: 20px;}
.deal-details-heading .brokerlist-location,.deal-details-heading .category-list{line-height:20px;font-size: 13px;color: #5d5d5d;}
.category-list{position: relative;padding-left: 25px;margin-bottom:8px;}
.category-list .sprite{width: 18px;height: 18px;position:absolute;left:auto; margin-left: -25px; top:0px;}
.deal-details{border: 1px solid #d9dcde;background-color: #fff;border-radius: 0 0 6px 6px}
.deal-details .position-relative > .img-fluid{width: 100%;}
.deal-info{padding: 15px;}
.deal-info .table td, .table th{padding:8px 0;font-weight: 600;font-size: 16px;}
.deal-info .table thead th{border-bottom: 1px solid #d9dcde;}
.deal-info .table thead th span{display: block;}
.text-price{font-size: 20px;padding-top: 5px;}
.list-item{color: #7d7d7d;}
.list-price{color: #323232;}
.btn-sm-blue{background-color: #4b9bde;color: #fff;border: 1px solid #4b9bde;display: inline-block;padding: 7.5px 20px;font-size: 14px;font-weight: 600;}
.btn-sm-blue:hover{background-color: #0e4570;color: #fff;border: 1px solid #0e4570;}
.btn-sm-blue:focus{border: 1px solid #4b9bde;}
.btn-sm-blue .sprite{width: 17px;height: 17px;margin-top:-3px;margin-right: 3px;}
.deal-description{margin-top: 30px;}
.deal-description h5{font-size: 16px;color: #323232;font-weight: 600;margin-bottom: 5px;}
.deal-description p{font-size: 13px;color: #424242;font-weight: 300;line-height: 21px;}
.social-share{border-top: 1px solid #d9dcde;padding-top: 20px;}
.social-share > span{float: left;font-size: 13px;font-weight: 300;color: #424242;margin-right: 10px;}
.social-share a{width: 34px;height: 32px;overflow: hidden;margin-right: 10px;}
.social-share.a2a_default_style .a2a_svg{border-radius: 50%;padding: 0 4px;margin-right: 0;}
.social-share .a2a_svg svg{top: 4px;}
body .a2a_menu a{font-family: 'Nunito Sans', sans-serif;border-color: transparent;}
body .a2a_menu a:hover,body .a2a_menu a:focus{border-width: 1px;border-color: transparent;}
.card.contact-broker{background-color: #fff;border-radius: 8px;border:1px solid #d9dcde;position: relative;}
.card.contact-broker .card-header{background-color: #3d93db;font-size: 18px;color: #fff;font-weight: 600;border-radius: 8px 8px 0 0;}
.card-header .sprite{width:20px;height:20px;margin-top: -2px;}
.contact-broker label{font-size: 14px;color: #000;margin-bottom: 0;}
.contact-broker .form-control{height: 42px !important;}
.contact-broker label.form-check-label{font-size: 13px;color: #424242;line-height: 19px;margin-bottom:8px;}
.contact-broker label.form-check-label a{color: #4b9bde;font-weight: 600;}
.contact-broker label.form-check-label a:hover{color: #036fc9;}
.contact-broker .custom-checkbox .custom-control-label::before{width: 12px;height: 12px;background-color: #fff;border: 1px solid #c1c1c1;border-radius: 0}
.contact-broker .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{background-color: #4b9bde;border-color: #4b9bde;}
.contact-broker .custom-control-label::after{left:-25px;top:2px;background-size:45% 45%;}
.similar-businesses{margin-top: 30px;margin-bottom: 30px;}
.similar-businesses h4{color: #323232;font-weight: 600;border-bottom: 1px solid #d9dcde;padding-bottom: 10px;margin-bottom: 20px;}
.similar-businesses-bx{border: 1px solid #d9dcde;border-radius: 6px;background-color: #fff;}
.similar-businesses-bx .img-fluid{width: 100%;border-radius: 6px 6px 0 0}
.similar-businesses-details{padding:10px 15px;}
.similar-businesses-details h6{font-size: 14px;color: #4b9bde;font-weight: 700;}
.similar-businesses-details p{font-size: 12px;color: #424242;line-height: 18px;margin-bottom: 5px;}
.similar-businesses-category{position: relative;padding-left:15px;margin-bottom:8px;}
.similar-businesses-category .sprite{width:14px;height: 14px;position:absolute;left:auto;margin-left: -15px; top: 3px;}
.certification-tooltip{width:90%;max-width:400px;}
.review-tooltip{width:90%;max-width:300px;}
.areas-tooltip{width:100%;max-width:350px;}
.custom-gutter .row{margin-left: -5px;margin-right: -5px;}
.custom-gutter .row .col{padding-left: 5px;padding-right: 5px;}
.rdw-editor-wrapper .rdw-editor-toolbar{border-color: #c1c1c1;margin-bottom: 0;border-bottom-width: 0;border-radius: 0}
.rich-editor.rdw-editor-main{border: 1px solid #c1c1c1;padding: 5px;background-color: #fafafa;}
.rdw-editor-wrapper .public-DraftStyleDefault-block{margin: 0;}
.DraftEditor-root{max-height: 275px;min-height: 200px; z-index: 0}
.rdw-editor-toolbar div[class*="-modal"]{height: auto;}
.review-usercomment ul br,.review-usercomment p ~ br,.review-usercomment br + br{display: none;}
@media(min-width:1200px){
	.header .navbar{padding:0 5rem;}	
}
@media(max-width:1199px){
	.container{max-width:inherit;}
	.banner .display-table{padding-bottom: 80px;}
	.deal-view .slick-prev{left:10px;}
	.deal-view .slick-next{right:10px;}
	.trending-franchise-bx p{font-size: 16px;line-height: 24px;}
	.btn-sm-blue{padding: 7.5px 10px;}
}
@media(max-width:991px){
	.full-container .slick-list{overflow: inherit;}
	.full-container .slick-slide .form-step{margin-left: 16px;margin-right: 16px;width: auto!important;display: block!important;}
	.search-result,.broker-list{margin-left: 16px;margin-right: 16px;}
	.btn-right{text-align: center;margin-top: 15px;}
	.profile-pic{width:125px;height:125px;margin-top: -62px;line-height: 110px;}
	.btn-camera{right: -10px;top: 80px;}	
	.user-info .btn-green{padding:10px 17px;height:auto;}
	.user-info .btn-bordered{padding:8px 17px;height:auto;}
}
@media (min-width:768px) and (max-width:991px){
	.sold-list-view{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-15px;margin-left:-15px;}
	.sold-list{-ms-flex: 0 0 50%;flex: 0 0 50%;max-width:50%;position:relative;width:100%;min-height: 1px;padding-right: 15px;padding-left:15px;border-bottom: 1px solid #dadada;}
	.sold-list + .sold-list:nth-child(2),.sold-list + .sold-list{border-top: 0;}
	.sold-list:nth-child(odd){border-right: 1px solid #dadada;}
	.sold-list:nth-child(odd):last-child{border-bottom:0;}
	.brokerlist-ratings{padding-left: 150px;}
	.banner h1{font-size:28px;}
	.matching-list.search-result p,.matching-list.search-result p em,.matching-list.search-result .d-inline-block{font-size: 18px;}
	.search-results .profile-bx.search-list .col-sm-5 .position-relative{min-height: 120px}
	.tmp-15{margin-top: 15px;}
	.type-of-business{padding: 0px 15px;}
	.type-of-business .form-check-label{font-size: 12.5px;}
	.search-control-border{padding: 10px 15px 5px;}
	.search-control-border > .row > div > .row > div{margin-bottom: 10px;}
	.search-control .search-control-border .Select{min-width:135px;}
	.dropdown-menu .form-group:not(.line-hr) {width:calc( 50% - 8px);float: left;}
	.search-control-border .dropdown-menu .form-control{max-width: 100%;}
	.line-hr{width: 15px;}
	.deal-info > .row{margin-left: -5px;margin-right: -5px;}
	.deal-info > .row > div{padding-left: 5px;padding-right: 5px;}
	.btn-sm-blue {padding: 7.5px 6px;}
	.contact-broker .g-recaptcha{transform:scale(0.60);-webkit-transform:scale(0.60);transform-origin:0 0;-webkit-transform-origin:0 0;}
	.brokers-list .search-result{margin-left: 0;margin-right: 0;}
}
@media(max-width:767px){
	.page-heading{padding-left:175px;padding-right:16px;font-size:24px;padding-top:15px;text-align:right;}
	.btn-blue{font-size: 14px;padding: 12px 20px;margin: 12px 0;}
	.stepform{padding-left: 0;padding-right: 0;}
	.border-bx{min-height:340px;}
	.begin-bx{padding:68px 15px;margin: 15px 15px 0;min-height:365px;}
	.begin-bx p{font-size:18px;line-height:28px;margin-top:15px;}
	.begin-bx p br{display: none;}
	.btn-bluedark, .show-matches,.btn-prev,.btn-green,.btn-bordered{font-size: 14px;height: auto;}
	.right-arrow{margin-top:0px;}
	.content-header{padding: 12px;height: 70px;}
	.content-header h4{font-size: 1rem;}
	.content-header h4 small{font-size: 90%;}
	.form-bx .geosuggest,.geosuggest{width:100%;}
	.form-bx{padding-left: 15px;padding-right: 15px;}
	.form-bx .form-control{height:50px;padding:8px 15px;font-size:14px;}
	.type-of-business .h-100{overflow: auto;height: calc(100% - 20px) !important;margin-top: 5px;align-items: start !important;}
	.buybusiness-option{padding-left: 15px;padding-right: 15px;}
	.type-of-business .col-md-3{margin:5px 0;}
	.custom-control{padding-left:0px;}
	.form-bx .custom-control-label{max-width: 100%;font-size:14px;padding:8px 8px 8px 30px;}
	.form-bx .custom-control-label::before,.form-bx .custom-control-label::after{left:10px;}
	.error-class{font-size: 14px;background-color: #fff;}
	.form-bx #massage{margin:0px;height:140px;width:100%;}
	.form-bx .form-group{margin: 0 auto;}
	.form-bx .form-group + .form-group{margin-top:15px;}
	.form-bx .form-group .sprite{top: 14px;}
	.footer-bx{padding:10px 15px;}
	.form-check-label img.d-block.m-auto.pb-2{display: inline-block !important; margin-right: 10px!important}
	.type-of-business .form-check-label{padding: 10px 2px;}
	.loader h3{font-size: 1.5rem;padding: 0 15px;line-height: 2rem;}
	.search-result h3{font-size: 26px;}
	.search-result .begin-bx p,.search-result .d-inline-block{font-size:18px;line-height:28px;}
	.broker-details{margin: 15px;}
	.broker-header{padding-right: 0}
	.broker-details .btn-blue-border{position: static;}
	.signup-bx p br{display: none;}
	.profile-info{margin-left:100px;}
	.maching-bx .col-md-6.col-lg-5{margin-top: 15px;}
	.profile-details .btn-bluedark{margin-left: 0;margin-top: 15px;}	
	.large-modal .modal-header .close{right:30px;top:28px;}
	.user-info,.bx-padding, .listing-bx,.profile-form,.write-review .profile-bx,.brokers-list .profile-bx,.search-control,.search-results .profile-bx.matching-list{padding-left: 16px;padding-right: 16px;}
	.search-control-border .dropdown-menu.mme-0{left: auto !important;right: 0;-webkit-transform: translate3d(0px, 34px, 0px)!important;transform: translate3d(0px, 34px, 0px)!important;}
	.search-result .form-bx,.search-result .begin-bx{padding: 16px;}
	.profile-pic{left: 15px;}
	.modal-dialog-centered .modal-title,.profile-modal .modal-title{font-size: 20px;padding-right:20px;}
	.opacity-5{height:140px;width:auto !important;max-width:none;}
	.public-view .profile-banner{margin-bottom: 60px;}
	.user-info h4,.public-view h5,.write-review h3,.write-review h4,.brokers-list h4{font-size:20px;}
	.user-info h6,.write-review h5{font-size:16px;}
	.location-info{font-size: 15px;margin-bottom: 10px;}
	.listing-details .font-weight-light,.ratings{margin-top: 10px;}
	.bx-padding, .listing-bx,.profile-form{padding-bottom: 15px;}
	.public-view .review-usercomment h5{font-size: 14px;}
	.listing-details{margin-left: 90px;}
	.listing-details .row{min-height: 80px;}
	.listing-details .font-weight-light{margin-left:-90px;}
	.brokers-list .geosuggest{padding-bottom: 0;}
	.matching-list p.brokerlist-firm{display: inline-block;}
	.banner h6,.deal-view .banner h6{font-size: 16px;margin-bottom: 5px;font-weight: 600;}
	.banner h1{font-size: 20px;}
	.sm-offset-5{margin-left: 41.666667%;margin-top: 20px;}	
	.brokerlist-info{margin-bottom: 15px;}
	.mmt-15{margin-top: 15px;}
	.brokerlist-ratings{margin: 15px 0;}
	.matching-list.search-result{margin-left: 0;margin-right: 0;}
	.matching-list.search-result h4{font-size: 18px;}
	.matching-list.search-result p,.matching-list.search-result p em,.matching-list.search-result .d-inline-block{font-size: 16px;}
	.matching-list.search-result p br{display: none;}
	.brokers-list .matching-list p.brokerlist-location{padding-left: 0;}
	.brokers-list .brokerlist-location .sprite{position: relative;top: -1px;margin-right: 5px;}
	.result-description label{margin-right: 10px;}
	.deal-details-heading h1{font-size: 18px;}
	.deal-info .table td, .table th{padding: 8px;}
	.deal-info .table thead th span{white-space: nowrap;}
	.text-price{font-size: 16px}	
	.deal-info .col-sm-auto{margin-top: 15px;}
	.card.contact-broker{margin-top: 15px;}	
	.card-body .custom-control{padding-left: 1.5rem;}
	.profile-modal .modal-body.plr-0{padding-left: 15px;padding-right: 15px;}
	.profile-modal .btn-toggle{padding-left: 30px;text-align: center;}
	.profile-modal .row.form-group .col-form-label{text-align: left;}
	.certification-tooltip,.review-tooltip{margin-left: 5%!important;}
}
@media(max-width:540px){
	.page-heading{padding-left:135px;padding-right:16px;font-size:18px;padding-top:12px;text-align:right;}
	.full-container .header .navbar{padding: .5rem 1rem;}
	.full-container .header .navbar-brand img{max-width: 110px;height: auto;}	
	.header .navbar-brand{padding: 0;}
	.loader h3{margin-top: -160px;}
	.search-result .img-fluid,.signup-section img.d-block.m-auto{max-width: 70%;height: auto;}
	.maching-bx{padding: 15px;}
	.word-break{word-break: break-all;}
	.footer-bx{padding:10px 5px;}
	.btn-bluedark,btn-prev,.btn-green,.btn-bordered{padding:10px 20px;}
	.show-matches{font-size: 14px;padding:10px 7px;}
	.btn-prev + .btn{margin-left:0px;}
	.navbar-collapse{left: 0px;right: 0px;top:50px;z-index: 10;position: absolute;background-color: #282828;padding:0 1rem 1rem;}
	.profile-banner{margin-bottom: 75px;}
	.profile-pic{width:80px;height:80px;margin-top: -40px;line-height:70px;}
	.btn-camera{right:-20px;top:35px;}
	.header .dropdown-menu{background-color: transparent;}
	.header .navbar-nav > .nav-item > .nav-link{padding:5px 0;}
	.header .dropdown-menu .nav-link {color: #cbcbcb;padding: .5rem 1rem;}
	.header .dropdown-menu .nav-link + .nav-link {border-top: 1px solid #545454;}
	.change-profile-banner,.logo-watermark{margin: 10px;}
	.logo-watermark{text-align: right;}
	.logo-watermark img{width: 80%;}
	.banner-selection .custom-radio{margin:10px;}
	.review-userpic{margin: 0 auto;float: none;display: block;}
	.review-usercomment{margin-left: 0;margin-top: 20px;}
	.profile-modal .modal-body{padding: 15px;}
	.testimonials{padding:0 15px;}
	.profile-modal .nav-tabs .nav-link{padding: 9.5px 8px;font-size: 15px;}
	.profile-modal .nav-tabs .nav-item{margin-right: 5px;}
	.sale-listingview .listing-details{padding-right: 20px;}
	.listing-details .i-delete{position: absolute;right: 0;top:30px;}
	.listing-details .i-edit{position: absolute;right:0px;top:5px;}
	.broker-pic{float: none;margin: 0 auto;}
	.matching-list{text-align: center;}
	.brokerlist-info{margin-left: 0;}
	.brokerlist-info p.brokerlist-location{display: inline-block;margin-bottom: 0;}
	.listing-listbx{padding: 5px;}
	.search-more a{margin-top: 0;margin-bottom: 10px;}
	.banner h6{font-size: 14px;margin-bottom: 5px;font-weight: 600;}
	.banner h1{font-size:18px;}
	.sm-offset-5{margin-left: 0;margin-top: 0;}
	.search-list .text-center.sm-offset-5 p{text-align: left;}
	.search-results .profile-bx.search-list .position-relative .img-fluid{width: 100%}
	.search-results .profile-bx.search-list .position-relative .img-fluid[src*="_1536332809"],.search-results .profile-bx.search-list .position-relative .img-fluid[src*="listing-logo-big"]{width: auto;max-width: 100%;}
	.profile-bx.search-list .position-relative .logo-watermark img.img-fluid{width: 35px;height: auto;}
	.search-list h6{margin-top: 20px;}
	.brokerlist-info{margin-bottom: 15px;}		
	.banner .display-table{padding-bottom:0px;}
	.search-control{margin-top: 15px;}
	.search-control .form-group .Select__control,.search-control .geosuggest__input-wrapper .form-control,.search-control .btn-bluedark,.search-control-border,#searchbox{border-radius: 4px;}
	.search-control .geosuggest__input-wrapper .form-control,#searchbox{border-left-width: 1px;}
	.rbt-input {border-left-width: 1px;}
	.search-control-border{border-top-width: 1px;margin-top: 0px;margin-bottom: 10px;}
	.search-control-border{padding-bottom:0px;}
	.search-control-border > .row > div > .row > div{margin: 5px 0;flex-basis:inherit;flex-grow:inherit;}
	.dropdown-menu .form-group:not(.line-hr) {width:calc( 50% - 8px);float: left;}
	.search-control-border .dropdown-menu .form-control{max-width: 100%;}
	.line-hr{width: 15px;}
	.search-control-border .col-sm-auto{text-align: center;}
	.franchises-bx{max-width: 265px;margin: 0 auto;display: block !important;}
	.brokers-list .form-inline{margin-top: 15px;}
	.brokers-list .Select{display: inline-block;vertical-align: middle;margin-left: 5px;}	
	.g-recaptcha{transform:scale(0.80);-webkit-transform:scale(0.80);transform-origin:0 0;-webkit-transform-origin:0 0;}
	.public-view .btn-sm.btn-bluedark.float-right{float: none !important;margin:10px auto 0;display: block;max-width: 170px;}
	.deal-info .table tr { display: table;width: 100%;}
	.deal-info .table th, .deal-info .table td { width: 50%;  display: inline-block; }
}


/* Chirag CSS Start */
.break {
    z-index: 0;
    position: relative;
}
.push_half--bottom {
    margin-bottom: 0.625em !important;
}
.push--top {
    margin-top: 1.25em !important;
}
.txt--subtle {
	font-size:16px;
	font-weight:600;
	color:#020202;
}
.txt--center {
    text-align: center;
}
.break__content {
    display: inline-block;
    padding: 0 0.5em;
    background: #fff;
}
.break::before {
    content: '';
    position: absolute;
	top: 50%;
	bottom: 50%;
    left: 0;
    right: 0;
    border-bottom: 1px solid #d9d9d9;
    z-index: -1;
}

/*Linkedin buton SignUp page*/

.btn-linkedin{
	background-color: #4F91D4;
	border: none;
	color: white;
	height: 50px;
	line-height: 46px;
	position: relative;
	text-align: left;
	width: auto;
	display: block;
	padding: 0 18px 0 6px;
	border-radius: 5px;
	text-decoration: none;
	cursor: pointer;
	margin: 0 auto;
}

.btn-linkedin:hover {
	background-color: #0e4570;
}

.btn-linkedin i {
	margin-right: 12px;
	font-size: 24px;
	line-height: 24px;
	width: 42px;
	height: 24px;
	text-align: center;
	display: inline-block;
	position: relative;
	top: 4px;
	border-right: 1px solid #00638b;
}

/* for remove extra margin for profile-bx */
.profile-bx.no-margin-top {
	margin-top: 0!important;
}

/* for wrapping all long textes without whitespaces */
p,h1,h2,h3,h4,h5,span,a{
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
}

/* NEW CSS FROM IGOR */

.no-value {
  color: rgb(223, 223, 223);
}


/* Featured Listing Block Start */

.featured-listing {
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 20px;
}

.featured-listing:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    transform: translateX(-50%);
    box-sizing: border-box;
    height: 1px;
    width: 300vw;
    background: #DADADA;
}

.gridview.gold .profile-bx{
    border: 4px solid #FFCB59;
}

.gridview.gold .profile-bx .btn-default{
    background-color: #FFCB59;
    width: 202px;
    height: 60px;
    color: white;
    font-size: 23px;
    position: absolute;
    right: 15px;
    bottom: 0;
    padding-top: 14px;
}

.gridview.gold .price {
    padding-bottom: 70px;
}

.gridview.silver .profile-bx{
    border: 2px solid #4396DC;
}

.gridview.silver .profile-bx .btn-default{
    height: 54px;
    width: 170px;
    font-size: 17px;
    box-sizing: border-box;
    padding-top: 16px;
    position: absolute;
    right: 15px;
    bottom: 0;
}

.gridview.silver .price {
    padding-bottom: 70px;
}

@media all and (max-width: 768px) {
    .gridview.silver .profile-bx .btn-default, .gridview.gold .profile-bx .btn-default{
        background: #e5e9ec;
        color: #5d5d5d;
        width: auto;
        position: static;
        font-size: 14px;
        height: auto;
    }

    .price {
        padding-bottom: 0!important;
    }
}



/* Featured Listing Block End */

/* #root{
    overflow: hidden;
} */
.searchbox {
	width: 100%;
}

.qlabel {
	width: 80%;
}

.rbt-input-multi .rbt-input-main {
	margin-top: 8px !important;
}


.messages-listings .Select-clear {
	font-size: 30px;
}

.messages-listings .Select-menu-outer {
	text-align: left;
}

.messages-listings .Select-placeholder {
	text-align: left;
}

.messages-listings .Select-arrow {
	border-width: 10px 7px 0px;
	margin-top: 8px;
}

.input_msg_write textarea {
	height: 75px !important;
}

.modal-featured .modal-content {
	/* width: 750px; */
}

.vertical-divider {
	position: absolute;
	display: table;
	text-align: center;
	height: 300px;
	width: 100%;
  }
  .vertical-divider .center-element {
	position: relative;
	display: table-cell;
	vertical-align: middle;
	font-size: 14px;
  }
  .vertical-divider .center-element:before,
  .vertical-divider .center-element:after {
	position: absolute;
	content: "";
	width: 1px;
	left: 50%;
	border-left: 1px dashed black;
  }
  .vertical-divider .center-element:before {
	bottom: 50%;
	top: 0;
	margin-bottom: 20px;
  }
  .vertical-divider .center-element:after {
	top: 50%;
	bottom: 0;
	margin-top: 20px;
  }


  .annual_profit .custom-control-label {
	text-align: center;
  }


  .Select.is-open {
    z-index: 1000 !important;
}

.hide_this_deal:hover,.add_to_favorite:hover {
	background-color: #e5e9ec;
}
.hide_this_deal,.add_to_favorite {
	padding: 3px;
}


.new_buyer_home .item {
    position:relative;
    padding-top:20px;
    display:inline-block;
}

.new_buyer_home .notify-badge {
    position: absolute;
    right:-15px;
    top:15px;
    background:red;
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color:white;
    padding:5px 10px;
    font-size:16px;
}

.new_buyer_home .keep-scrolling::-webkit-scrollbar {
    display: none;
}


.new_buyer_home .keep-scrolling {
  -ms-overflow-style: none;  
  scrollbar-width: none;
}

.new_search_console .searchbox .form-control {
	height: 49px;
}

.new_search_console .Select__control {
	height: 38px;
}

.new_search_console .search-control-border {
	border: none;
	background-color: white;
	padding: 0;
}

.new_search_console .search-control-border .dropdown .btn-secondary.dropdown-toggle {
	color: #aaa;
}

.new_search_console .search-control-border .form-control {
	background-color: white;
}

.new_search_console .search-control-border .form-control {
	height: 38px;
}

.new_search_console .search-control-border .Select-placeholder {
	color: #aaa;
}

.custom-menu-category div.rbt-input-multi {
	/* background-color: #ececec; */
	color: #6D7277;
}

.pref_locations_input {
	min-height: 47px;
}

.custom-menu-category .dropdown-toggle {
	background-color: #ececec;
}

.profile--new input {
	min-height: 48px;
	
}

.profile--new .Select__control {
	border-radius: 25px;
}

.preferences_new .btn.btn-filter {
	background-color: white;
	border: 1px solid #eee;
	font-size: 14px;
	justify-content: left;
}

.preferences_new .geosuggest__input.form-control {
	background-color: white !important;
	font-size: 14px;
}

.preferences_new .custom-menu-category .form-control {
	min-height: 46px !important;
	font-size: 14px;
}

.preferences_new .rbt-input-multi .rbt-input-main {
	margin-top: -3px !important;
	color: #6D7277;
}

.preferences_new .rbt-input-multi .rbt-input-main::placeholder {
	color: #6D7277;
}

.company_input .rbt-input-hint input  {
	display: none !important;
}
