.boost-panel {

  .main-boost-title {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 52px;
  }

  .boost-card {
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border: none;
    margin: 10px 0;
    height: calc(100% - 20px);
    .card-body {
      display: flex;
      flex-direction: column;
      padding: 32px 25px;

    }
    .card-text,
    .card-title {
      text-align: center;
    }
    .btn {
      text-align: center;
      width: 100%;
      margin-top: auto;
    }

    .img-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      img {
        width: 100%;
        max-width: 296px;
      }
    }
  }

  @media(max-width: 768px) {
    padding: 30px 0;

    .main-boost-title {
      font-size: 24px;
      margin-bottom: 30px;
    }

    .boost-card {
      .card-body {
        padding: 24px 16px;

      }

      .img-holder {
        margin-bottom: 16px;
      }
    }
  }
}
