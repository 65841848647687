.deal-notes_wrapper {
  display: flex;
  margin-bottom: 20px;
}

.deal-notes {
  &.btn {
    width: 45px;
    height: 22px;
    -webkit-border-radius: 44px;
    -moz-border-radius: 44px;
    border-radius: 44px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #ffffff;
    background-color: #3269A3;
    
    &.btn-default {
      background-color: #848484;
    }
  }

  &_title {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
  &_deal-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #518ED4;
  }
}

.deal-note-modal.modal-dialog-centered {
  width: 543px;
  max-width: 100%;
  .modal-content {
    padding: 30px;
    width: 543px;
    max-width: 100%;
  }
  .modal-title {
    line-height: 1.1;
  }

  .modal-header {
    position: relative;
    justify-content: flex-start;
    padding: 0 0 28px;
    border-bottom: 1px solid #dee2e6;

    .close {
      top: 0;
      right: 0;
      margin: 0;
    }
  }
  .modal-body {
    padding: 0;
  }

  .modal-footer {
    padding: 0;

    .form-control {
      margin: 30px 0 0!important;

      &.invalid {
        border-color: #dc3545;
      }

    }
    .error {
      color: #dc3545!important;
      margin: 0 auto 0 0;
    }
  }

  .btn-primary {
    margin-top: 20px;
    width: 100%;
    color: #fff;
    background-color: #282828FF;
    border: 0;

    &:hover {
      background-color: #29be37;
      color: #fff;
    }

    &[disabled] {
      color: #ADB9C2;
      background-color: #ebebeb;
    }
  }
}

.note {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #dee2e6;

  &-content {
    display: flex;
    flex-direction: column;
  }

  &-time {
    color: #858585;
  }

  &-body {
    word-break: break-word;
  }

  &-delete {
    cursor: pointer;
    padding: 5px;

    &:hover {
      opacity: .8;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}


.note-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 17px;

  background: #518ED4;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
}
