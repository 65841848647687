@import "~bootstrap/scss/bootstrap.scss";
@import "../../node_modules/simplebar/dist/simplebar.css";

$primary-blue: #3890da;
$primary-black: #282828;
$secondary: #829fb9;
$secondary-text: #6D7277;

%box-shadow-1 {
  box-shadow: 1px 1px 20px #e1e1e1;
}

%box-shadow-2 {
  box-shadow: 1px 1px 15px #c2c2c2;
}

@import "shared";
@import "home-page";
@import "listing-page";
@import "profile-broker-page";
@import "advertise-page";
@import "subscription-page";