.custom-alert {
  width: 100vw;
  top: 0;
  margin: 0!important;

  &.info {
    max-width: 100%;
    height: 50px;
    background-color: #bcddf8;
    padding: 5px 60px 5px 40px;
    position: relative;
    margin-bottom: 20px!important;
    i {
      position: absolute;
      left: 15px;
    }
  }
}

