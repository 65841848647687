.subscription-page {
  // Layout
  .container {
    @include media-breakpoint-up(xl) {
      max-width: 1150px;
    }
  }

  .custom-switch {
    padding-left: 0;

    .custom-control-label {
      min-width: 65px;

      &:before {
        left: 0;
        top: -4px;
        width: 65px;
        height: 30px;
        border-radius: 25px;
        background-color: $primary-blue;
        border-color: $primary-blue;
      }

      &:after {
        top: -0.5px;
        left: 4px;
        background-color: #fff;
        height: 22px;
        width: 22px;
        border-radius: 100%;
      }
    }
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(35px)
  }


  .pricing-card {
    display: flex;
    flex-direction: column;
    min-width: 256px;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 25px #D9D9D9;
    border-radius: 20px;
    height: 100%;
    padding: 20px;

    &-head {
      text-align: center;
      margin-bottom: 30px;

      .content-divider {
        margin: 0 0 0 -20px;
        width: calc(100% + 40px);
      }
    }

    &__title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 0;

      &-sm {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 18px;
      }
    }

    &__price {
      color: $primary-blue;
      font-size: 36px;
      margin-bottom: 10px;

      &-addition {
        font-size: 12px;

        &.crossed {
          text-decoration: line-through;
        }
      }
    }

    &__annual {
      color: #6D7277;
      margin-top: -8px;
    }

    &__features {
      padding-left: 0px;
      font-size: 14px;
      list-style: none;
      margin-right: -10px;

      &-tooltip {
        font-size: 18px;
        color: #ADB9C2;
        line-height: 1;
        margin-left: 2px;
      }

      li {
        position: relative;
        line-height: 1.45;
        margin-bottom: 14px;

        // &:before {
        //   content: "";
        //   position: absolute;
        //   transform: translate3d(-16px, 6px, 0);
        //   width: 6px;
        //   height: 6px;
        //   border-radius: 100%;
        //   background-color: $primary-blue;
        // }
      }
    }

    &__cancel-btn {
      color: #829FB9;
      border-bottom: 2px solid #829FB9;
      padding-bottom: 1px;

      &:hover {
        color: darken(#829FB9, 15%);
        border-bottom-color: darken(#829FB9, 15%);
      }
    }

    &__select-btn {
      position: relative;
      min-width: 170px;
    }

    .background-divider {
      width: calc(100% + 40px);
      left: -20px;
    }
  }

  .pricing-plans-wrapper {
    margin-top: -30px;

    .simplebar-content {
      padding: 30px 15px 40px !important;
    }

    @include media-breakpoint-up(sm) {

      .simplebar-content {
        padding: 30px 20px 40px !important;
      }
    }
  }

  .accordion {
    .card {
      border: 0;
      background-color: transparent;
      margin-bottom: 20px;
    }
    .card-header {
      padding: 0;
      border: 0;

      .btn {
        background-color: #FFFFFF;
        box-shadow: 1px 1px 25px #D9D9D9;
        border-radius: 10px;
        padding: 12px 20px;
        border: 0;
        color: #373C41;
        font-weight: 500;
        font-size: 16px;
        text-align: left;

        .btn-icon {
          padding-left: 5px;
          font-size: 18px;
          color: #829FB9;
        }
      }
    }
    .card-body {
      margin-top: 15px;
      margin-bottom: 20px;
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      font-size: 14px;
    }
  }

  .btn-premium-no-action {
    cursor: default !important;
  }

  .btn-premium-no-action:hover {
    color: #fff !important;
    background-color: #3890da !important;
  }

  .btn-premium-no-action:focus {
    box-shadow: none !important;
  }
}
