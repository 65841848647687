.row-gutter-10 {
  margin-left: -5px;
  margin-right: -5px;

  & > [class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.row-gutter-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;

  & > [class*="col"] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

.row-gutter-20 {
  margin-left: -10px;
  margin-right: -10px;

  & > [class*="col"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row-gutter-sm-50 {
  @include media-breakpoint-up(sm) {
    margin-left: -25px;
    margin-right: -25px;

    & > [class*="col"] {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

.mw-350px {
  max-width: 350px;
}

.unlocked-deals-link {
  box-shadow: 1px 1px 10px #3890da;
  margin: 20px 0!important;
}

[aria-expanded="false"] .d-when-expanded {
  display: none;
}

[aria-expanded="true"] .d-when-not-expanded {
  display: none;
}

[aria-expanded="true"] .d-when-expanded {
  display: initial;
}

/* Shared Styles */
body {
  font-family: "Poppins", sans-serif;
  // padding-top: 30px;
  //padding-bottom: 60px;
  background-color: #f8f8f8;
  color: #2a2f34;

  // General
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-weight: 600;
    font-family: inherit;
    color: #373c41;
  }

  a {
    color: $primary-blue;
    text-decoration: none;

    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }

  p {
    font-family: inherit;
    color: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .h1 {
    font-size: 18px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  .h2 {
    font-size: 18px;
  }

  .h3 {
    font-size: 16px;
  }

  .h4 {
    font-size: 14px;
  }

  .text-sm {
    font-size: 14px;
  }

  .text-xsm {
    font-size: 12px;
  }

  .text-dark {
    color: #282828 !important;
  }

  .text-primary-blue {
    color: $primary-blue !important;
  }

  .text-secondary {
    color: $secondary !important;
  }

  .text-secondary-2 {
    color: $secondary-text !important;
  }

  .font-weight-500 {
    font-weight: 500;
  }

  .vertical-align-text-bottom {
    vertical-align: text-bottom;
  }

  .lh-0 {
    line-height: 0;
  }

  .link-special {
    color: $primary-blue;
    border-bottom: 2px solid $primary-blue;
    font-weight: 600;

    &:hover {
      color: darken($primary-blue, 15%);
    }
  }

  .link-pale {
    color: #9BA8B3;

    &:hover {
      color: darken(#9BA8B3, 18%);
    }
  }

  .text-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .divider {
    height: 1px;
    background-color: #B5CEE3;
    margin: 15px 0;
  }

  .d-block-upon-md-important {
    @include media-breakpoint-down(md) {
      display: block !important;
    }
  }

  .paragraph-circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    position: relative;
    top: -1px;

    &.paragraph-circle--primary-blue {
      background-color: $primary-blue;
    }
  }

  .blurred {
    filter: blur(4px);
  }

  .overlay-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .content-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    margin: 30px 0;
  }

  .background-divider {
    position: absolute;
    left: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    top: 50%;
    transform: translateY(-50%);
  }

  // Icons
  .icon {
    display: inline-block;
    color: inherit;
    height: 1em;
    width: 1em;
  }

  .icon use {
    /* icon secondary color - fill */
    fill: inherit;
  }

  .icon.icon--outline use {
    /* icon secondary color - stroke */
    stroke: inherit;
  }

  // Buttons
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    padding: 9px 15px;
    border-radius: 25px;
    outline: 0;
    box-shadow: none;

    &.btn-sm {
      padding: 4.5px 15px;
    }

    &.btn-text-xsm {
      font-size: 12px;
    }
  }

  .btn-unstyled {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
  }

  .btn-link {
    font-weight: 400;
    border-bottom: 1px solid $primary-blue;
    color: $primary-blue;

    &:active, &:focus {
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
      color: darken($primary-blue, 18%);
    }
  }

  .btn-primary {
    color: #fff;
    background-color: $primary-black;
    border: 0;

    &:hover {
      background-color: #29be37;
      color: #fff;
    }

    &[disabled] {
      color: #ADB9C2;
      background-color: #ebebeb;
    }
  }

  .btn-outline-primary-blue {
    border: 1px solid $primary-blue;
    color: $primary-blue;
    font-weight: 400;

    &:hover {
      background-color: $primary-blue;
      color: #fff;
    }
  }

  .btn-outline-primary-green {
    border: 1px solid #29be37;
    color: #29be37;
    font-weight: 400;

    &:hover {
      background-color: #29be37;
      color: #fff;
    }
  }

  .btn-outline-primary-red {
    border: 1px solid red;
    color: red;
    font-weight: 400;

    &:hover {
      background-color: red;
      color: #fff;
    }
  }

  .btn-primary-blue {
    background-color: $primary-blue;
    color: #fff;

    &:hover {
      background-color: darken($primary-blue, 8%);
    }
  }

  .btn-secondary {
    border: 0;
    color: $primary-blue;
    font-weight: 500;
    background-color: #f7f7f7;
  }

  .btn-tag {
    border: 1px solid #848484;
    border-radius: 5px;
    height: 22px;
    padding: 2px 8px;
    font-size: 12px;
    color: #848484;
    font-weight: 400;

    &.is-active {
      background-color: #848484;
      color: #fff;
    }
  }
  .search_console_mobile .btn-filter{
    &:hover {
      color: #6D7277;
    }
    &:active {
      color: rgb(51, 51, 51);
      background-color: rgba(0, 0, 0, 0);
      border-color: rgb(51, 51, 51);
    }
  }

  .btn-filter {
    height: 35px;
    font-size: 13px;
    font-weight: 400;
    background-color: #ececec;
    color: #6D7277;
    border-radius: 25px;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      background-color: darken(#ececec, 10%);
    }

    &.active {
      border: 1px solid $primary-blue !important;
      color: $primary-blue !important;
      background-color: transparent !important;
    }
  }

  .btn-filter-completed-keywords {
    .form-control {
      border: none;
      background-color: #585858;
      color: #FFFFFF;

      .rbt-input-main {
        color: #FFFFFF;
      }

      .rbt-input-main::placeholder {
        color: #FFFFFF;
        text-align: center;

      }
    }
  }

  .btn-filter-completed {
    font-size: 13px;
    font-weight: 400;
    background-color: #585858;
    color: #FFFFFF;
    border-radius: 25px;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      background-color: darken(#ececec, 10%);
    }

    &.active {
      border: 1px solid $primary-blue !important;
      color: $primary-blue !important;
      background-color: transparent !important;
    }
  }

  .btn-input-right {
    position: absolute;
    right: 0;
    top: 0;
    // height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    // color: $primary-blue;
  }

  .tag {
    display: inline-flex;
    align-items: center;
    height: 36px;
    padding: 4px 18px;
    background-color: #F7F7F7;
    color: #0F4778;
    font-size: 13px;
    border-radius: 25px;
    max-width: 100%;

    @include media-breakpoint-up(sm) {
      font-size: 14px;
      height: 40px;
    }
  }

  // Forms
  .form-control:not(textarea) {
    border-radius: 25px;
    border: 1px solid #eee;
    height: 40px;
    padding: 5px 18px;
    font-size: 14px;

    &::placeholder {
      color: #6d7277;
    }

    &.form-control--filter {
      height: 35px;
      font-size: 13px;
      font-weight: 400;
      background-color: #ececec;
      color: #6D7277;
      border-radius: 25px;
      padding: 5px 15px;

      @include media-breakpoint-down(sm) {
        font-size: 12px !important;
      }
    }

    &.form-control--bordered {
      border: 1px solid #848484;
    }
  }

  textarea.form-control {
    max-height: 300px;
    font-size: 14px;

    &.border-primary-blue {
      border: 1px solid $primary-blue;
    }
  }

  .custom-control.custom-radio {
    padding-left: 1.25rem;

    .custom-control-label::before,
    .custom-control-label::after {
      left: -1.25rem;
    }
  }

  .custom-control-label::before {
    border: 1px solid #e4e4e4;
  }

  .custom-checkbox {
    .custom-control-label::before,
    .custom-control-label::after {
      width: 20px;
      height: 20px;
    }
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: rgba($primary-blue, .4);
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary-blue;
  }

  .custom-control-label.text-sm {
    padding-top: 3px;
    padding-left: 3px;
  }

  /* Table */
  .table {
    border-bottom: 1px solid #F0F2F3;

    thead th, tbody td {
      border-top: 1px solid #F0F2F3;
      border-left: 1px solid #F0F2F3;
      font-size: 14px;

      &:last-child {
        border-right: 1px solid #F0F2F3;
      }
    }

    thead {
      th {
        font-weight: 500;
        padding-top: 20px;
        padding-bottom: 10px;
        border-bottom: 0;
      }
    }

    tbody {
      td {
        color: $secondary-text;
      }
    }
  }

  /* Horizontal Scroll Layout */
  .horizontal-scroll-layout {
    display: flex;
    overflow-x: auto;
    padding-bottom: 30px;

    .simplebar-content {
      display: flex;
    }

    .simplebar-track.simplebar-horizontal {
      height: 8px;
      bottom: 8px;
      overflow: initial;

      &::after {
        content: "";
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        width: calc(100% - 4px);
        height: 2px;
        background-color: #b5cee3;
        border-radius: 6px;
      }

      .simplebar-scrollbar {
        top: 0;
        z-index: 2;

        &::before {
          background-color: #ffffff;
          box-shadow: 0px 0px 10px #b8cbdb;
          border-radius: 41px;
          opacity: 1;
        }
      }
    }
  }

  /* Vertical Scroll */
  .vertical-scroll-layout {
    margin: -10px -15px -15px;
    padding: 10px 15px 15px;
    max-height: 300px;

    .simplebar-track.simplebar-vertical {
      width: 10px;
    }

    .simplebar-scrollbar::before {
      background-color: #dfdfdf;
    }

    &.vertical-scroll-layout--h250 {
      max-height: 250px;
    }

    &.vertical-scroll-layout--h400 {
      max-height: 400px;
    }
  }

  /* Business Card */
  .business-card {
    display: flex;
    font-size: 14px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 1px 1px 15px #c2c2c2;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }

    .business-card-list-item {
      padding-left: 80px;
      @include media-breakpoint-down(sm) {
        padding-left: 20px;
      }
    }

    .business-card_price_button {
      width: 250px;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    &:not(:last-of-type):not(.business-card--horizontal) {
      margin-bottom: 15px;
    }

    &.business-card--horizontal {
      font-size: 13px;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        max-width: 186px;
      }

      .business-card__content {
        padding: 15px 10px 0px;
      }

      .business-card__title {
        font-size: 14px;
        margin-bottom: 12px;
      }

      .business-card__location {
        margin-bottom: 10px;
      }

      .business-card__image {
        position: relative;
        height: 137px;
      }

      .business-card__price {
        position: absolute;
        left: 15px;
        top: 10px;
        font-size: 13px;
        white-space: nowrap;
        padding-left: 12px;
        padding-right: 12px;

        @include media-breakpoint-up(md) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &__actions {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 40px;

      @media (max-width: 1040px) {
        position: static;
      }

      @include media-breakpoint-down(sm) {
        position: relative;
        padding-left: 5px;
        top: -5px;
      }

      .btn {
        color: rgba($primary-black, .7);
        padding: 3px;

        &:hover {
          color: $primary-black;
        }

        &.active {
          color: #e12c3d;
        }
      }
    }

    &__image {
      min-width: 166px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        min-width: 100px;
      }

      img {
        height: 100%;
        width: 250px;
        @include media-breakpoint-down(sm) {
          width: 100%!important;
        }
        object-fit: cover;
      }
    }

    &__content {
      position: relative;
      padding: 20px;
    }

    &__title {
      font-size: 16px;
      font-weight: 500;
      padding-right: 250px;
      @media (max-width: 1040px) {
        padding-right: 20px;
      }
    }

    &__location {
      font-size: 12px;
      color: #0f4778;
    }

    &__footer-btn {
      width: calc(100%);
      margin-left: -10px;
      border-radius: 0;
    }
  }

  /* Content */
  .content-card {
    @extend %box-shadow-2;
    border-radius: 10px;
    padding: 25px 14px;
    background-color: #fff;

    @include media-breakpoint-up(sm) {
      padding: 25px 18px;
    }

    @include media-breakpoint-up(xl) {
      padding: 40px;
    }

    h2 {
      font-size: 16px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }

    h3 {
      font-size: 16px;
    }

    .title-1 {
      font-size: 14px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }
  }

  .content-body {
    font-size: 14px;

    h2, h3, p {
      margin-bottom: 18px;
    }

    p {
      line-height: 24px;
    }
  }

  /* Rating */
  .rating {
    color: #d8d8e0;

    &[data-rating="1"] {
      span:first-child {
        color: #FFC107;
      }
    }

    &[data-rating="2"] {
      span:nth-child(-n+2) {
        color: #FFC107;
      }
    }

    &[data-rating="3"] {
      span:nth-child(-n+3) {
        color: #FFC107;
      }
    }

    &[data-rating="4"] {
      span:nth-child(-n+4) {
        color: #FFC107;
      }
    }

    &[data-rating="5"] {
      color: #FFC107;
    }
  }

  .chat {
    @extend %box-shadow-2;
    background-color: #fff;
    border-radius: 10px;

    .vertical-scroll-layout {
      padding: 0;
      margin: 0;
      max-height: 100%;
    }

    .simplebar-track.simplebar-vertical {
      right: -14px;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      &-avatar {
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }

      &-title {
        font-size: 18px;
        font-weight: 600;
        margin-left: 10px;
        padding-right: 10px;
        margin-bottom: 0;
      }

      &-btn {
        color: $secondary;
        font-size: 20px;

        &:hover {
          color: darken($secondary, 12%);
        }
      }
    }

    &-body {
      padding: 20px;
      height: 270px;
      transition: height .3s ease;

      @include media-breakpoint-up(sm) {
        height: 310px;
      }

      @include media-breakpoint-up(md) {
        height: 330px;
      }

      @include media-breakpoint-up(lg) {
        height: 350px;
      }

      @include media-breakpoint-up(xl) {
        height: 368px;
      }

      &__message {
        display: flex;
        max-width: calc(100% - 20px);

        &:not(:first-child) {
          margin-top: 20px;
        }

        &-meta {
          color: $secondary-text;
          font-size: 12px;
          margin-bottom: 4px;
        }

        &-body {
          padding: 10px 20px;
          border-radius: 6px;
          font-size: 14px;
          width: auto;
        }
      }

      &__message--sent {
        margin-left: auto;
        justify-content: flex-end;

        .chat-body__message-body {
          background-color: #D8EDFF;
        }
      }

      &__message--received {
        .chat-body__message-body {
          background-color: #EAF4FC;
        }
      }
    }

    &-footer {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      padding: 20px 10px 30px 20px;

      &-btn {
        color: $primary-blue;

        &:hover {
          color: darken($primary-blue, 15%);
        }
      }
    }
  }
}

@media screen and (min-width: 676px) {
  .modal-dialog {
    max-width: 800px;
  }
}

.delete-modal {
  &__footer {
    @include media-breakpoint-down(sm) {
      .btn {
        font-size: 11px;
        margin: 5px 0;

        & + .btn {
          margin-left: 5px;
        }
      }
    }
  }
}
.header-navbar.navbar {
  max-width: 100vw;
  .navbar-collapse{
    max-height: calc(100vh - 117px);
    padding: 8px 0;
    .navbar-nav {
      display: flex;
      align-items: center;
    }
  }
  .nav-link, .nav-item {
    i {
      margin-right: 5px;
    }
  }

  @media (max-width: 991px) {
    .navbar-collapse {
      overflow: auto;
    }
    .nav-link, .nav-item {
      text-align: center;
    }
  }
  @media (max-width: 540px) {
    .dropdown-item:hover, .dropdown-item:focus {
      color: #fff;
      text-decoration: none;
      background-color: #1f1e1e;
    }
  }

  @include media-breakpoint-down(540px) {
    .navbar-collapse {
      max-height: calc(100vh - 90px);
    }

  }
}

.seller-financing {
  @include media-breakpoint-down(sm) {
    &__radio {
      display: inline-flex;
      align-items: center;

      label {
        position: static;
        margin-bottom: 0;
        margin-right: 0.5rem;
      }
      input {
        position: static;
        margin: 0;
      }
    }
  }

}

#listingmodal .input-group-text {
  border-radius: 25px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 180px;
}

.price_button_no_cursor {
  background-color: #FFF !important;
  cursor: auto;
}

.price_button_no_cursor:hover {
  background-color: #f7f7f7;
  color: #3890da;
}

.simplebar-scrollbar::before {
  background-color: #3890da !important;
}

.profile-card__nav li a span.linkItem {
  @include media-breakpoint-up(lg) {
    left: 56px;
  }

}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  height: 12px !important;
}

.horizontal-scroll-layout .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  top: -1px !important;
}

.topMenu {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  overflow-x: auto;
}

.topMenu li {
  display: block;
  flex: 0 1 auto; /* Default */
  list-style-type: none;
}

.topMenu li.active {
  border-bottom: 2px solid #829fb9;
  // border-bottom-style: dashed;
}

.rbt-token-removeable {
  color: #FFF !important;
  background-color: #282828 !important;
  border-radius: 25px !important;
  line-height: 1.5rem;
}

.rbt-input-wrapper {
  margin-top: -3px !important;
}

.business-card__description {
  white-space: initial !important;
  word-break: break-word;
}

.reason_to_join::-webkit-input-placeholder {
  font-style: italic;
}


/* width */
.custom_scroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
.custom_scroll::-webkit-scrollbar-track {
  background: #b5cee3;
  border-radius: 10px;
}

/* Handle */
.custom_scroll::-webkit-scrollbar-thumb {
  background: #3890da;
  border-radius: 10px;
  background: url('https://stage.app.biznexus.com/_design/grip-lines-vertical-solid.svg') #3890da;
  // background-size:8px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

/* Handle on hover */
.custom_scroll::-webkit-scrollbar-thumb:hover {
  background: url('https://stage.app.biznexus.com/_design/grip-lines-vertical-solid.svg') #3890da;
  // background-size:8px 16px;
  background-repeat: no-repeat;
  background-position: center;
}


.tableFixHead {


  table {
    // margin: 1em 0 !important;
    // border-collapse: collapse !important;
    // border: 0.1em solid #d6d6d6 !important;
  }

  caption {
    font-style: italic !important;
    // padding: 0.25em 0.5em 0.5em 0.5em !important;
  }

  td:nth-of-type(3) {
    // padding: 0.25em 0.5em 0.25em 1em !important;
    // word-break: break-all;
  }

  th {
    vertical-align: bottom !important;
    background-color: rgb(40, 40, 40);
    color: #fff !important;
  }

  // tr:nth-child(even) th[scope=row] {
  //   background-color: #f2f2f2 !important;
  // }

  // tr:nth-child(odd) th[scope=row] {
  //   background-color: #fff !important;
  // }

  // tr:nth-child(even) {
  //   background-color: rgba(0, 0, 0, 0.05) !important;
  // }

  // tr:nth-child(odd) {
  //   background-color: rgba(255, 255, 255, 0.05) !important;
  // }

  td:nth-of-type(3) {
    font-style: italic !important;
  }


  /* Fixed Headers */

  th {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0px !important;
    z-index: 2 !important;
  }

  th[scope=row] {
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0 !important;
    z-index: 1 !important;
  }

  th[scope=row] {
    vertical-align: top !important;
    color: inherit !important;
    background-color: inherit !important;
    background: linear-gradient(90deg, transparent 0%, transparent calc(100% - .05em), #d6d6d6 calc(100% - .05em), #d6d6d6 100%) !important;
  }

  table:nth-of-type(2) th:not([scope=row]):first-child {
    left: 0 !important;
    z-index: 3 !important;
    background: linear-gradient(90deg, #666 0%, #666 calc(100% - .05em), #ccc calc(100% - .05em), #ccc 100%) !important;
  }

  /* Strictly for making the scrolling happen. */

  @media (max-width: 1260px) {
    overflow: auto;
    th {
      position: static!important;
    }

  }

}


.dot {
  height: 8px;
  width: 8px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
}


.favorite-item {
  background: transparent;
  width: 100%;
  min-height: 35px;
  padding: 6px 8px;
  border-radius: 6px;
  margin: 7px;
  color: #8799AC;
  display: flex;
  align-items: center;

  i {
    display: flex;
    margin-right: 3px;
  }
}

.favorite-item-active {
  background: #E4ECF3;
  width: 100%;
  min-height: 35px;
  padding: 6px 8px;
  border-radius: 6px;
  margin: 7px;
  color: #8799AC;
  display: flex;
  align-items: center;

  i {
    display: flex;
    margin-right: 3px;
  }
}

.nav-tabs-list {
  .favorite-item,
  .favorite-item:hover,
  .favorite-item-active {
    min-height: 35px;
    height: auto !important;
  }
}

.favorite-item:hover {
  background: #E4ECF3;
  color: #829fb9;
  cursor: pointer;
  width: 100%;
  padding: 6px 8px;
  border-radius: 6px;
  margin: 7px;
  display: flex;
  align-items: center;

  i {
    display: flex;
    margin-right: 3px;
  }
}
.favorite-subitem {
  display: flex;
  padding: 6px 12px;
  width: calc(100% - 48px);
  margin-left: 48px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1rem;
  color: #8799AC;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 0;

  & + .favorite-subitem {
    margin-top: 5px;
  }

  &:hover {
    background: #E4ECF3;
  }

  .badge {
    display: flex;
    height: 18px;
    justify-content: center;
    align-items: center;
    background: #879FB7;
    border-radius: 44px;
    color: #fff;
    padding: 10px;
    margin-left: auto;
  }
}

.search_fixed_width {
  max-width: 960px !important;
}

.margin_center {
  margin-left: auto!important;
  margin-right: auto!important;
}

.cursor_pointer {
  cursor: pointer;
}

.tableFixHead {
  th {
    // word-break: break-word;
    padding-top: 13px !important;
  }
}

.signup-section .rbt-input-hint {
  display: none !important;
}
.middle-content .rbt-input-hint {
  width: 100%;

  &-container {
    width: 100%;

    & > div {
      width: 100%;
      .rbt-input-main {
        width: 100% !important;
      }
    }
  }
}

.simplebar-offset {
  max-width: 100% !important;
}
.sticky-sidebar {
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 2;
  max-height: calc(100vh - 140px);
  overflow: hidden auto;
}
.folder-icon-wrapper {
  margin-right: 7px;
  display: inline-flex;
  width: 15px;
  height: 15px;
  padding: 3px;
  .folder-icon {
    display: flex;
    transform: rotate(-90deg);
    height: 12px;
    width: 12px;
    transition: transform .3s ease;

    &.open {
      transform: none;
    }
    .icon {
      height: 12px;
      width: 12px;
    }
  }
}
.ac-sidebar {

  &-content {
    position: sticky!important;
    top: 0px!important;
    z-index: 2!important;

    .sticky-advanced-search {

      .scrollable-filter {
        max-height: calc(100vh - 340px);
        overflow: hidden auto;
      }
      .dropdown.show {
        .dropdown-menu.show {
          width: 100%;
          .dropdown-item {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .toggle-filter-button {
    display: none;
  }
  .sticky-advanced-search {
    .scrollable-filter {
      max-height: calc(100vh - 320px);
      overflow: hidden auto;
    }
  }
  .search-now-button {
    display: none;
  }
  @media (max-width: 990px) {
    .search-now-button {
      display: flex;
    }

    .toggle-filter-button {
      
      &:hover {
        color: #3890da;
        opacity: .8;
      }
      position: fixed;
      min-width: 120px;
      top: 130px;
      display: flex;
      align-items: center;
      z-index: 3;
      left: 50%;
      transform: translateX(-50%);

      padding: 8px 10px 8px 18px;
      height: 52px;

      background: #FFFFFF;
      border: 1px solid #D3D3D3;
      box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.08);
      border-radius: 72px;
    }

    .ac-sidebar-content{
      padding-top: 60px;

      .sticky-advanced-search {
        display: none;
      }

      &.open {
        display: block;
        position: fixed!important;
        top: 116px!important;
        z-index: 2!important;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fafafa;
        padding-top: 60px;

        .sticky-advanced-search {
          display: block;
        }
      }
    }
  }
  @media (max-width: 540px) {

    .ac-sidebar-content {
      padding-top: 50px;
      &.open {
        top: 86px!important;
      }
    }

    .toggle-filter-button {
      top: 110px;
    }
  }
}

.target-list-select {
  cursor: pointer;
  border-radius: 25px;
  .Select-multi-value-wrapper {
    border-radius: 25px;
    display: flex;
  }
  .Select__control {
    cursor: pointer;
    display: flex;
    border-radius: 25px;
    border: 1px solid #eee;
    height: 40px;
    padding: 5px 18px;
    font-size: 14px;
    background: #fff;
    font-weight: 400;
    line-height: 3;
    color: #495057;

    .Select-placeholder {
      cursor: pointer;
      font-weight: 400;
      line-height: 3;
      color: #495057;
      border-radius: 25px;
    }
  }
  .Select-arrow-zone {
    height: 40px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.sidebar-card-design {
  background-color: #f8f8f8;
  box-shadow: 1px 1px 20px #e1e1e1;
  border-radius: 10px;
  padding: 20px 20px !important;
}

.italized-placeholder::placeholder {
  font-style: italic;
}

.modal-dialog.new-modal {
  border-radius: 10px;
  overflow: hidden;

  .modal-content {
    .modal-header {
      padding: 20px;
      border-bottom: none;
      .modal-title {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #222529;
      }

      .close {
        font-weight: 100;
        font-family: sans-serif;
        color: #9F9F9F;
      }
    }
    .modal-body {
      padding: 0 20px;
      .industry-filter {
        padding: 0;
      }
    }
    .modal-footer {
      padding: 20px;
      display: flex;
      justify-content: center;
      border-top: none;

      .btn {
        min-width: 70px;
      }
    }
  }
}


.search_input_mobile {
  input {
    @include media-breakpoint-down(sm) {
      font-size: 11px;

      &::placeholder {
        font-size: 9px;
      }
    }
  }
}
.Select.invalid {
  .Select__control {
    border-color: #dc3545;
  }
}

.search_console_mobile {
  button {
    @include media-breakpoint-down(sm) {
      font-size: 12px !important;
    }
  }

  .custom-checkbox label {
    @include media-breakpoint-down(sm) {
      font-size: 12px !important;
    }
  }
}

.topMenu {
  li span {
    @include media-breakpoint-down(sm) {
      white-space: nowrap;
    }
  }
}

.boosted-profile-home {
  @include media-breakpoint-up(sm) {
    position: relative;
    left: -45px;
  }

}

.profile-home-horizontal {
  &.potential_card {
    // margin: 5px;
  }
  &.potential_card {
    .business-card {
      padding: 10px 16px;
    }

    &:not(.home) {
      .business-card {
        margin-bottom: 10px;
      }
    }
  }


  &.potential_card.home {
    .business-card {
      flex-wrap: nowrap;
      padding: 10px 16px;
    }

    .professional-card__title {
      font-size: 10px!important;
      margin-bottom: 5px!important;
    }

    .professional-card__title + span {
      font-size: 8px!important;
    }
  }

  @include media-breakpoint-down(sm) {
    flex: 0 0 40%;

    &.potential_card:not(.home) {
      margin: 10px 0;
      width: 100% !important;
      max-width: none;
      min-width: 100% !important;

      .business-card {
        padding: 10px;
        flex-direction: row;
        width: 100% !important;

        &__image {
          width: auto;
        }

        &__mail {
          width: auto !important;
          margin-left: auto;
          margin-right: 10px;
          right: 10px !important;
        }
      }
    }
    &.potential_card.home {
      .business-card__image {
        width: auto;
      }

      &:nth-child(2) {
        display: none;
      }

      .professional-card__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
        margin-right: 5px;
      }
    }
  }

  a.btn {
    position: absolute;
    @include media-breakpoint-down(sm) {
      left: 0;
      margin: 0px;
    }
  }
}

.flex-no-wrap {
  flex-wrap: nowrap!important;
}


.favorites_checkbox {
  @include media-breakpoint-up(sm) {
    padding-left: 0px;
  }
}

.mobile_listing_actions {
  @include media-breakpoint-down(sm) {
    position: relative !important;
    height: 80px;
  }
}

.btn-deal-cta {
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
  }
}

.search_input_mobile div.rbt-input-multi {
  height: auto;
  min-height: 40px;
}

.search_input_mobile {
  padding-right: 8rem;
}

.create_deal_btn {
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
  }
}
.custom-multiselect {
  .Select-arrow-zone {
    vertical-align: top;
  }
}

.custom-multiselect-dropdown-icon {
  position: relative;
  display: inline-block;
  height: 22px;
  width: 40px;
  background-color: #4482ce;
  border-radius: 4px;
  margin-top: 5px;
  vertical-align: top;
  align-self: start;
  &::before {
    content: '';
    background-color: #fff;
    position: absolute;
    display: block;
    width: 1px;
    height: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &::after {
    content: '';
    background-color: #fff;
    position: absolute;
    display: block;
    height: 1px;
    width: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    opacity: .8;
  }
}

.create_deal_btn {
  @include media-breakpoint-up(sm) {
    width: 305px !important;
    position: absolute !important;
    right: 0px !important;
    top: -5px !important;
    display: flex !important;
  }
}

.advanced-keywords-input .rbt-input-main {
  min-height: 35px;
  height: auto;
  font-size: 13px;
  font-weight: 400;
  background-color: #ececec;
  color: #6D7277;
  padding: 5px 15px;
}

.advanced-keywords-input .rbt-input-multi {
  height: auto;
  background-color: #ececec;
  min-height: 35px;
  padding: 2px 15px;
}


.advanced-search-console .form-control--filter:not(.advanced-search-location-input) {
  background-color: white !important;
}


.advanced-search-location-input {
  border-radius: 0px !important;
}

.advanced-filter-section {
  position: relative;
  // border: 1px solid #B5CEE3;
  background-color: #ececec;
}

.advanced-dropdown {
  z-index: 99999;
}
.advanced-dropdown .dropdown-item {
  font-size: 13px;
  padding: 7px !important;
  color: #6D7277;

}

.advanced-dropdown-filter .advanced-dropdown-prefix__control {
  height: 35px !important;
  font-size: 13px;
  font-weight: 400;
  color: #6D7277;
  border-radius: 25px;

  border: 1px solid #eee;
  width: 100%;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.advanced-dropdown-filter .advanced-dropdown-prefix__single-value {
  color: #6D7277;
}

.favorite-list-table td {
  padding: 0.5rem;
}

.navbar_header {
  position: sticky;
  top: 0;
  z-index: 999;
}

.search-header:focus {
  border: none;
  box-shadow: none;
}

.search-header {
  border: none !important;
  box-shadow: none !important;
}

.header-group {
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
  }
}


.vertical-divider {
  position: absolute;
  display: table;
  text-align: center;

  @include media-breakpoint-down(sm) {
    display: none;
  }


  height: 100%;
  width: 100%;

  .center-element {
    position: relative;
    display: table-cell;
    vertical-align: middle;

    &:before, &:after {
      position: absolute;
      content: "";
      width: 1px;
      left: 50%;
      border-left: 1px solid #B5CEE3;
    }

    &:before {
      bottom: 50%;
      top: 0;
      margin-bottom: 20px;
    }

    &:after {
      top: 50%;
      bottom: 0;
      margin-top: 20px;
    }
  }
}

.dropdown-listing {
  @include media-breakpoint-up(sm) {
    left: -130px !important;
  }
  @include media-breakpoint-down(sm) {
    left: -30px !important;
  }

}

.scroll-button {
  position: fixed;
  right: 50%;
  bottom: 40px;
  height: 40px;
  font-size: 24px;
  z-index: 1;
  cursor: pointer;
  background-color: rgb(40, 40, 40);
}

.hp-active-item {
  background-color: #3890da!important;
  color: #fff !important;
}

.pref_locations_input {
  height: 35px;
  font-size: 13px;
  font-weight: 400;
  background-color: #ececec;
  color: #6D7277;
  border-radius: 25px;
  padding: 5px 15px;
}

.pref_loc_inp {
  min-height: 38px;
}
.btn-info {
  color: #fff;
}
.btn.btn-tag:hover {
  border-color: var(--bs-btn-active-border-color);
}
.Select__indicator-separator {
  display: none;
}

#signupform .Select-control.Select__control {
  border-radius: 2px !important;
  background-color: #fafafa;
  border: 1px solid #c1c1c1;
  min-height: 47px;
}
.Select-multi.Select-control {
  .Select__multi-value {
    display: flex;
    background-color: rgba(0, 126, 255, .08);
    border-radius: 2px;
    border: 1px solid #c2e0ff;
    font-size: .9em;
    line-height: 1.4;
    margin-top: 5px;
    vertical-align: top;
    color: #3890da;
    margin-left: 0;
    margin-right: 5px;
    flex-direction: row-reverse;
    .Select__multi-value__label {
      background-color: rgba(0, 126, 255, .08);
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      cursor: default;
      color: #3890da;
      padding: 2px 5px;
    }
    .Select__multi-value__remove {
      background-color: rgba(0, 126, 255, .08);
      cursor: pointer;
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
      border-right: 1px solid #c2e0ff;
      border-right: 1px solid rgba(0, 126, 255, .24);
      padding: 2px 3px 3px;

    }
  }

}
.user-banner {
  margin-top: 20px;
  margin-bottom: 20px;
}
.banner-drop {
  width: 100%;
  cursor: pointer;
  height: 80px;
  border: 3px dashed #495057;
  color: #495057;
  background-color: #ececec;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
  & > div {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
.profile-pic-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  canvas {
    margin-bottom: 15px;
  }
}
.signup-bx .btn:active {
  border: none;
}
.console-wrapper {
  .col-sm-3 .btn {
    color: #fff;
  }
}
.download-btn.btn-primary.btn-secondary {
  background-color: #fff;
}
.delete-btn.btn {
  border: none;
  color: #dc3545!important;
}
.error-text {
  color: #dc3545!important;
}
.profile--new .Select-control.Select__control {
  min-height: 48px;
}
.Select__control.Select-suggested {
  border-radius: 0!important;
  border: 1px solid #ececec;
  min-height: 40px;
  padding: 0;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  background-color: #ececec;
  cursor: pointer;
  &.Select-focused {
    outline: none;
    box-shadow: none;
    border: 1px solid #3791da;
    background-color: transparent;

  }
  .Select__single-value {
    color: #3791da;
  }
  & + .Select__menu {
    z-index: 999;
  }
}

.Select-control.Select__control {
   border-radius: 25px !important;
   border: 1px solid #eee;
   min-height: 40px;
   padding: 0 7px;
   font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  .Select__value-container {
    padding: 10px;
    margin: 0;
  }

  input {
    min-height: 0;
  }

  .Select__placeholder {
    margin: 0;
    padding: 0;
  }

  .Select__input-container {
    padding: 0;
    margin: 0;
  }
}
.more-info-in-chat {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}
.similar-businesses-wrapper {
  .business-card {
    height: 500px;

    @media (max-width: 768px) {
      height: auto;
    }
  }
  .business-card__title {
    padding-right: 0;
  }
}
.profile-broker-page .review {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  font-size: 14px;
}
.profile-broker-page .reviews-card {
  background-color: #f8f8f8;
}

.chat-box-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 7px 10px;
  border: 1px solid;
  border-radius: 15px;
  width: 100px;
  & + .chat-box-btn {
    margin-left: 15px;
  }
}

.chat-box-btn i {
  font-size: 18px!important;
}
.deal-page-businesses {
  padding: 0 10px 20px;
  .business-card__title {
    padding: 0;
  }
}
.Select-multi.Select-control .Select__indicator {
  border: none;
  position: relative;
  display: inline-block;
  height: 22px;
  width: 40px;
  background-color: #4482ce;
  border-radius: 4px;
  margin-top: 10px;
  vertical-align: top;
  align-self: start;
  &::before {
    content: '';
    background-color: #fff;
    position: absolute;
    display: block;
    width: 1px;
    height: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &::after {
    content: '';
    background-color: #fff;
    position: absolute;
    display: block;
    height: 1px;
    width: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    opacity: .8;
  }

}
.Select-control .Select__indicator {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 6px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
  padding: 0;
  box-sizing: border-box;
  margin-right: 10px;

  svg {
    display: none;
  }
}
.Select-control.Select__control--menu-is-open .Select__indicator {
  transform: rotate(180deg);
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

img.background {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  -webkit-filter: blur(8px); /* Safari 6.0 - 9.0 */
  filter: blur(8px);
}

.link-hover:hover {
  color: #3890da !important;
  text-decoration: none !important;
}
.btn:not(:disabled):hover {
  cursor: pointer;
}

.link-hover.active {
  color: #3890da !important;
  text-decoration: none !important;
}

.link-hover {
  color: white;
}

.zoom:hover {
  transform: scale(1.5);
}


.form-register .steps {
  margin-top: 20px;
  margin-bottom: 30px;
}

.form-register .steps ul {
  display: flex;
  display: -webkit-flex;
  list-style: none;
  padding-left: 0;
  justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
}

.form-register .steps li,
.form-register .steps li.current {
  outline: none;
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -webkit-outline: none;
}

.form-register .steps li div {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background: #C8C8C8;
  margin-right: 111px;
  position: relative;
  outline: none;
}

.form-register .steps li div::before {
  position: absolute;
  content: "";
  background: #C8C8C8;
  width: 120px;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 100%;
}

.form-register .steps li.last div {
  margin-right: 0;
}

.form-register .steps li.last div::before {
  content: none;
}

.form-register .steps li.current div,
.form-register .steps li.current div:active,
.form-register .steps li.done div::before,
.form-register .steps li.done div,
.form-register .steps li.done div:active {
  background: #4D8FD5;
}

.form-register .steps li .current-info {
  // display: none;
}

.form-register .steps li .label {
  position: absolute;
  top: 25px;
  // right: -10px;
  font-size: 14px;
}

.form-register .steps li.done .label {
  color: #4D8FD5;
}

.form-register .steps li.current .label {
  color: #4D8FD5;
}

.form-register .steps li .label {
  color: #C8C8C8;
}


.form-register .steps li .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.setup-button {
  width: 125px;
  height: 130px;
  border-radius: 10px;
  border: 1px solid #C8C8C8;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.setup-button-subtitle {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #C8C8C8;
}

.setup-content {
  // text-align: center;
  // margin: 25px;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.setup-button-title {
  font-size: 16px;
  font-weight: 600;
}

.setup-button-active {
  border: 1px solid #4D8FD5;
  background-color: #EBF3FD;
  box-shadow: 1px 1px 20px #e1e1e1;
  //width: 145px;
  //height: 140px;
}


.subscription-page {
  div.btn-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    left: -30px;
    position: relative;
  }

  div.btn-container i {
    display: inline-block;
    position: relative;
    top: -9px;
  }

  div.btn-container label {
    font-size: 13px;
    color: #424242;
    font-weight: 500;
  }

  .btn-color-mode-switch {
    display: inline-block;
    margin: 0px;
    position: relative;
  }

  .btn-color-mode-switch > label.btn-color-mode-switch-inner {
    margin: 0px;
    width: 160px;
    height: 30px;
    background: $primary-blue;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
    display: block;
  }

  .btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
    content: attr(data-on);
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    top: 7px;
    right: 20px;
    color: #fff;
  }

  .btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    width: 70px;
    height: 26px;
    background: #fff;
    border-radius: 26px;
    position: absolute;
    left: 2px;
    top: 2px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 4px 0px;
  }

  .btn-color-mode-switch > .alert {
    display: none;
    background: #FF9800;
    border: none;
    color: #fff;
  }

  .btn-color-mode-switch input[type="checkbox"] {
    cursor: pointer;
    width: 50px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0px;
  }

  .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
    background: $primary-blue;
    color: black;
  }

  .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after {
    content: attr(data-on);
    left: 88px;
    background: #FFFFFF;
  }

  .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before {
    content: attr(data-off);
    right: auto;
    color: white;
    left: 20px;
  }

  .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
    /*background: #66BB6A; */
    /*color: #fff;*/
  }

  .btn-color-mode-switch input[type="checkbox"]:checked ~ .alert {
    display: block;
  }

}

.box-potential {
  position: relative;
}

.box-potential:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0%;
  left: 0%;
  box-shadow: inset 116px 32px 28px #f5f6f7;
  transform: rotate(180deg);
}


.chat-conversation {
  height: calc(100vh - 350px);
}

.people-conversation {
  height: calc(100vh - 184px);
}

.console-right-arrow {
  position: absolute;
  right: 15px;
}

.white-card {
  display: block;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(187, 187, 187, 0.25);
  border-radius: 10px;
  padding: 14px 20px;
}

.font-weight-800 {
  font-weight: 800;
}

.time_date_responsive {
  @include media-breakpoint-up(sm) {
    position: absolute;
  }
  @include media-breakpoint-down(sm) {
    position: relative;
  }
}

.header-navbar.navbar .header-group.d-none.d-xl-flex {
  @media (max-width: 1250px) {
    display: none!important;
  }
}

.console-signups {
  .btn-success {
    border-radius: 25px;
  }
}

.btn-blue-login {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
  background-color: #4F91D4;
  color: #fff;
  border-radius: 25px;
  padding: 10.5px 44px;
  border: none;
}

.btn-blue-login:hover {
  background-color: #0e4570;
}

.gray-help-text {
  color: #848484 !important;
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.tableFixHead th {
  vertical-align: bottom !important;
  background-color: #282828;
  color: #fff !important;
  &:first-child {
    background-color: #282828!important;
  }
}
.navbar .input-group .btn {
  height: 100%;
  border: none!important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-title .btn-close:focus {
  box-shadow: none;
}
.dropdown .btn:not(.btn-filter):hover, .dropdown .btn:not(.btn-filter):active, .dropdown .btn:not(.btn-filter):focus {
  border-color: transparent!important;
}
.advanced-filter-section .btn:hover, .advanced-filter-section .btn:active, .advanced-filter-section .btn:focus {
  border-color: transparent!important;
}
.scrollable-filter .btn:hover, .scrollable-filter .btn:active, .scrollable-filter .btn:focus {
  border-color: transparent!important;
}
.search-control-border {
  position: relative;
}
.tooltip.show.bs-tooltip-auto {
  padding: 0!important;
}
.listing-modal form .row.mb-3 label.col-md-4.col-form-label {
  text-align: end;
}
.listing-modal h5.modal-title {
  margin-top: 0;
}
.project-header .btn:hover {
  border-color: #CCC;
}
.dropdown.dropstart .btn {
  border: none;
}
.tooltip-icon {
  display: inline-block;
  margin-left: 4px;
}
.login-active-tab {
  position: relative;
  //border-bottom: 5px solid #4F91D4 !important;
  //border-radius: 0px !important;
}

.login-active-tab:before {
  content: '';
  position: absolute;
  height: 5px;
  left: 50%;
  bottom: -5px;
  width: 100%;
  transform: translateX(-50%);
  background-color: #4F91D4;
}

.login-circle-help {
  min-width: 20px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 13px;
  padding: 1px;
  margin-right: 3px;
  background-color: #4F91D4;
  color: #fff;
  text-align: center;
}

.signup-testimonial {
  border: 1px solid #d9d9d9;
  border-radius: 25px;
  padding: 15px;
}

.signup_warning {
  font-size: 14px;
  color: white !important;
  background-color: #ffc107 !important;
  display: block;
  padding: 0.5em;
}

.signup_warning:empty {
  display: none;
}

.broker-premium-badge {
  display: flex;
  padding: 2px 5px;
  background: #ffd701;
  border-radius: 15px;
  font-size: 9px;
  line-height: 1;
  margin-top: -3px;
  position: relative;
  z-index: 1;
}

.img-fluid.premium {
  border: 2px solid #ffd701;
  border-radius: 50%;
}

.invalid-dropdown {
  border: 1px solid red;
  border-radius: 25px;
}
