.advertise-page {
  .container {
    @include media-breakpoint-up(xl) {
      max-width: 1170px;
    }
  }

  .cta-banner {
    box-shadow: 1px 1px 25px #D9D9D9;
    border-radius: 20px;
    background-color: #fff;
    padding: 30px 25px;
    text-align: center;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: initial;
    }

    @include media-breakpoint-up(xl) {
      padding: 15px 100px;
      align-items: center;
    }

    &__image {
      width: 100%;
      margin-bottom: 24px;

      img {
        max-width: 180px;
      }

      @include media-breakpoint-up(md) {
        max-width: 180px;
        margin-bottom: 0;
      }
    }

    &__content {
      max-width: 650px;
      width: 100%;

      @include media-breakpoint-up(md) {
        padding: 15px 0 15px 30px;
      }

      &-title {
        color: $primary-blue;
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 24px;
      }

      &-description {
        line-height: 1.6;
        color: #6D7277;
        margin-bottom: 20px;
        font-size: 14px;
      }

      .btn {
        min-width: 180px;
      }
    }
  }

  .option-card {
    background-color: #fff;
    box-shadow: 1px 1px 15px #C2C2C2;
    border-radius: 10px;
    height: 100%;
    max-width: 460px;

    &__image {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    &__content {
      padding: 30px;
    }

    &-title {
      font-size: 18px;
      margin-bottom: 12px;
      line-height: 1.38
    }

    &-description {
      font-size: 14px;
      color: #6D7277;
      margin-bottom: 14px;
    }

    &-price {
      color: $primary-blue;
      font-size: 18px;
      margin-bottom: 20px;
      font-weight: 500;

      &--old {
        font-size: 14px;
        color: #DC3545;
        text-decoration: line-through;
      }
    }
  }

  .modal.modal--promote {
    .modal-dialog {
      max-width: 750px;
    }
    .modal-content {
      padding: 5px;

      @include media-breakpoint-up(md) {
        padding: 30px 60px;
      }
    }
    input.form-control {
      background-color: rgba(0, 0, 0, 0.05);
    }
    .modal-cancel {
      border-radius: 0;
      padding: 2px;
      font-weight: 500;
    }
    .modal-boost {
      min-width: 180px;
    }
  }
}
