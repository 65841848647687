.incoming_msg_img {
    display: inline-block;
    width: 6%;
  }
 
  .received_msg {
    display: inline-block;
    padding: 0 0 0 0px;
    vertical-align: top;
    width: 100%;
   }
   .received_withd_msg p {
    
    border-radius: 3px;
    
    font-size: 14px;
    margin: 0;
    padding-top: 10px;
    width: 70%;
  }
  .time_date {
    
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
  }
  .received_withd_msg { width: 100%;}
  /* .mesgs {
    float: left;
    padding: 30px 15px 0 25px;
    width: 60%;
  } */
  
   .sent_msg p {
    
    border-radius: 3px;
    font-size: 14px;
    margin: 0; color:#fff;
    padding: 5px 10px 5px 12px;
    width:100%;
  }
  .outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
  .sent_msg {
    float: right;
    width: 46%;
  }
  .input_msg_write input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    
    font-size: 15px;
    min-height: 48px;
    width: 100%;
  }
  
  .type_msg {margin-top: 10px; position: relative;}
  .msg_send_btn {
    
    border: medium none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    height: 33px;
    position: absolute;
    right: 0;
    top: 11px;
    width: 33px;
  }
  .messaging { padding: 0 0 50px 0;}


  .msg_history {
    overflow:   auto;
    /* position:   absolute; */
    bottom:     0;
    width: 100%;
    max-height: 100%;
    
    
  }

  .mesgs {
    height: 100%;
    min-height: 450px;
    overflow:   none;
    position:   relative;
    width:      100%;

  }

  .mesgs_empty {
    margin: 0;
    /*width: 200px;*/
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 18px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .user_selected {
    background-color: #CFDFF0;
  }

  .chat-row:hover {
    background-color: #CFDFF0;
  }

.chat-row {
  border-radius: 10px;
}

.chat-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 5px;

  width: 119px;
  height: 65px;

  background: rgba(77, 143, 213, 0.1);
  border-radius: 10px;

  color: #4D8FD5;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}

  .messages-listings .Select-placeholder {
    font-weight: bold;
    opacity: 1 !important;
  }

  .messages-listings .Select-value {
    width: 95%;
  }

  .user_not_selected {
    background-color: rgb(245, 246, 247);
  }