.profile-broker-page {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }

  // Layout
  .container {
    @include media-breakpoint-up(xl) {
      max-width: 1180px;
    }
  }

  .layout-content, .layout-sidebar {
    padding-left: 10px;
    padding-right: 10px;
  }

  .layout-content {
    flex: 0 0 100%;
    max-width: 100%;

    @include media-breakpoint-up(xl) {
      flex: 0 0 790px;
      max-width: 790px;
    }
  }

  .layout-sidebar {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .sidebar-map {
    width: 100%;
    height: 520px;
    border-radius: 10px;
  }

  /* Profile Card */
  .profile-broker-card {
    @extend %box-shadow-2;
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;

    &__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;

      @media (min-width: 420px) {
        font-size: 18px;
      }

      @include media-breakpoint-up(md) {
        font-size: 22px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 24px;
      }
    }

    &__content {
      padding: 14px 14px 25px;
      flex: 0 0 100%;
      max-width: 100%;
      order: 2;

      @include media-breakpoint-up(sm) {
        padding: 18px;
      }

      @include media-breakpoint-up(lg) {
        padding: 26px;
        order: initial;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      @include media-breakpoint-up(xl) {
        padding: 32px 32px 40px 40px;
      }
    }

    &__features {
      padding: 20px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }

    &__contact {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 35px 20px;

      @include media-breakpoint-up(lg) {
        flex: 0 0 350px;
        max-width: 350px;
      }

      &-bg {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &-avatar {
        border: 3px solid #fff;
        width: 120px;
        height: 120px;
        border-radius: 120px;
      }

      &-social {
        font-size: 34px;

        a {
          color: #fff;
        }
      }
    }
  }

  /* Reviews */
  .reviews-card {
    @extend %box-shadow-2;
    padding: 25px 20px;
    border-radius: 10px;

    &__title {
      font-size: 14px;

      @include media-breakpoint-up(sm) {
        font-size: 16px;
      }

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
  }

  .review {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 10px;
      margin-bottom: 15px;
    }

    &-author {
      font-weight: 600;
      margin-bottom: 0;
      padding-right: 15px;
    }

    &-title {
      font-size: 14px;
    }

    &-data {
      color: #6D7277;
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  .testimonial {
    font-size: 14px;
    padding: 15px;
    border: 1px solid rgba(#000, 0.05);
    border-radius: 10px;
    margin-bottom: 20px;

    &-body {
      color: #6D7277;
      margin-bottom: 12px;
    }

    &-author {
      font-weight: 500;
      color: #0F4778;
    }
  }
}
