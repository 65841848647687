.confirmation-popup {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: #282828;
    max-width: 400px;
    width: auto;
    min-height: 82px;
    border-radius: 5px;
    padding: 15px;
  }
  
  .modal-header {
    padding: 0;
    margin-bottom: 10px;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    .modal-title {
      color: #fff;
      font-weight: 500;
      font-size: 12px;
    }
  }
  .modal-footer {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    
    .confirmation-btn {
      border: 1px solid #FFFFFF;
      border-radius: 72px;
      background-color: transparent;
      color: #fff;
      width: calc(50% - 8px);
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;

      &:hover {
        opacity: .8;
      }
      
      &.danger {
        background: #CA4242;
        border-color: #CA4242;
        color: #fff;
      }
      &.success {
        background: #29be37;
        border-color: #29be37;
        color: #fff;
      }
    }
  }

}
