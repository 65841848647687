.project-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .project-title {
    display: inline-flex;
    align-items: center;
    margin: 10px 10px 10px 0;
  }
  .btn-actions {
    display: flex;
    margin: 10px 0;
  }
}

.collapsible-search {
  & + .collapsible-search {
    margin-top: 5px;
  }
  border-bottom: 1px solid rgba(0,0,0,.125);
  border-radius: 0 0 0.25rem 0.25rem;

  &__top {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    min-height: 1px;
    padding: 1rem 1.25rem 1rem calc(1.25rem + 30px);
    border-bottom: none;
    cursor: pointer;

    &.open {
      border-radius: 0.25rem 0.25rem 0 0;
    }

    & > i {
      font-size: 18px;
      position: absolute;
      left: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
    }

    h5 {
      display: flex;
      margin-bottom: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;

      .fa-pen {
        padding: 0 10px;
        font-size: 12px;
        display: inline-block;
      }
    }

    .link {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.02em;
      white-space: nowrap;
    }
  }

  &__bottom {
    position: relative;
    .card {
      border-top: none;
      border-bottom: none;
      border-radius: 0 0 0.25rem 0.25rem;

      &-body {
        padding: 1rem 1.25rem;

        .table {
          margin: 0;
        }
      }
    }

    .content-divider {
      background-color: rgba(0,0,0,.125);
      margin-top: -18px;
      margin-bottom: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 1.25rem;
      top: 0;
      z-index: 1;
      width: calc(100% - 2.5rem);
      height: 1px;
      background-color: rgba(0,0,0,.125);
    }
  }
  .match-alert {
    margin: 12px 45px 0 0;
    display: flex;
    align-self: center;
  }
}

.btn.btn-unstyled:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.list-caption {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 4px;
  color: #A0A0A0;
}

.project-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.list-subtitle {
  display: flex;
  width: 230px;
  height: 42px;
  background: rgba(210, 223, 239, 0.25);
  border-radius: 6px;
  color: #8399AE;
  font-size: 20px;
  line-height: 22px;
  padding: 5px 13px;
  align-items: center;
  font-weight: 500;

  .icon, i {
    display: flex;
    margin-right: 13px;
  }
}

.h2.project-header {
  margin: 0 0 25px 0;

  @media (max-width: 768px) {
    margin: 0 0 15px 0;
  }
}
